import React from 'react';
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import './Wrapper.css'
const Wrapper = ({children}) => {
    return (
                <div className="page-container" data-v-71ee931c="" data-v-272ae9a3="">
                    <Header/>
                        {children}
                    <Footer/>
                </div>
    );
};

export default Wrapper;