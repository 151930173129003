import React from 'react';
import Logo from '../../Assets/Img/logo.png'
import MaskImg from '../../Assets/Img/logo-white.svg'
import MaskImgWrapper from '../../Assets/Img/menu-wrap.svg'
import images from '../../utils/ImportImage'
import Down from '../../Assets/Img/down.svg'
import World from '../../Assets/Img/globus.svg'
import Moon from '../../Assets/Img/moon.svg'
import './Header.css'

const Header = () => {
    const [open, setOpen] = React.useState(false);
    const [openMobile, setOpenMobile] = React.useState(false);
    const [byCryptoOpen, setByCryptoOpen] = React.useState(false);
    const [spotOpen, setSpotOpen] = React.useState(false);
    const [derivativesOpen, SetDerivativesOpen] = React.useState(false);
    const [copyTradingOpen, setCopyTradingOpen] = React.useState(false);
    const [wealthOpen, setWealthOpen] = React.useState(false);
    const [moreOpen, setMoreOpen] = React.useState(false);
    return (
        <header className="bx-navigation">
            <div className="access-tips-wrap" data-v-73d4b658="" data-v-98383e2a="">
                <div className="download-guide-wrap app-download" style={{display: "none"}} data-v-55493da7=""
                     data-v-73d4b658="">
                    <div className="app-box" data-v-55493da7="">
                        <div className="icon" data-v-55493da7="">
                            <img src={Logo} alt="" data-v-55493da7=""/>
                        </div>
                        <div className="rate" data-v-55493da7=""><p className="app-name" data-v-55493da7="">BingX
                            APP</p>
                            <div className="stars" data-v-55493da7="">
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                     xmlns="http://www.w3.org/2000/svg" data-v-55493da7="">
                                    <path
                                        d="M7 0L8.85152 4.4516L13.6574 4.83688L9.99583 7.9734L11.1145 12.6631L7 10.15L2.8855 12.6631L4.00417 7.9734L0.342604 4.83688L5.14848 4.4516L7 0Z"
                                        fill="#FFC929" data-v-55493da7=""></path>
                                </svg>
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                     xmlns="http://www.w3.org/2000/svg" data-v-55493da7="">
                                    <path
                                        d="M7 0L8.85152 4.4516L13.6574 4.83688L9.99583 7.9734L11.1145 12.6631L7 10.15L2.8855 12.6631L4.00417 7.9734L0.342604 4.83688L5.14848 4.4516L7 0Z"
                                        fill="#FFC929" data-v-55493da7=""></path>
                                </svg>
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                     xmlns="http://www.w3.org/2000/svg" data-v-55493da7="">
                                    <path
                                        d="M7 0L8.85152 4.4516L13.6574 4.83688L9.99583 7.9734L11.1145 12.6631L7 10.15L2.8855 12.6631L4.00417 7.9734L0.342604 4.83688L5.14848 4.4516L7 0Z"
                                        fill="#FFC929" data-v-55493da7=""></path>
                                </svg>
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                     xmlns="http://www.w3.org/2000/svg" data-v-55493da7="">
                                    <path
                                        d="M7 0L8.85152 4.4516L13.6574 4.83688L9.99583 7.9734L11.1145 12.6631L7 10.15L2.8855 12.6631L4.00417 7.9734L0.342604 4.83688L5.14848 4.4516L7 0Z"
                                        fill="#FFC929" data-v-55493da7=""></path>
                                </svg>
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                     xmlns="http://www.w3.org/2000/svg" data-v-55493da7="">
                                    <path
                                        d="M7 0L8.85152 4.4516L13.6574 4.83688L9.99583 7.9734L11.1145 12.6631L7 10.15L2.8855 12.6631L4.00417 7.9734L0.342604 4.83688L5.14848 4.4516L7 0Z"
                                        fill="#FFC929" data-v-55493da7=""></path>
                                </svg>
                            </div>
                        </div>
                    </div>
                    <a rel="noopener" href="https://bingx.com/en/download/" className="download-box" data-v-55493da7="">
                        Download
                    </a></div>
            </div>
            <nav className="navigation-inner" data-v-98383e2a="">
                <div className="nav-left" data-v-98383e2a="">
                    <a href="https://bingx.com/en/" style={{maskImage: `url(${MaskImg})`}}
                       className="logo bx-nav-svg-icon" data-v-98383e2a=""/>
                    <div className="tablet-menu-wrap" data-v-1f10ba96="" data-v-98383e2a="">
                        <div onClick={() => setOpen(!open)} className="tm-icon-wrap" data-v-1f10ba96="">
                            <div style={{maskImage: `url(${MaskImgWrapper})`}} className="tm-icon bx-nav-svg-icon"
                                 data-v-1f10ba96=""></div>
                        </div>
                        {open ?
                            <div data-v-1f10ba96="" className="tablet-menu-dialog">
                                <ul data-v-1f10ba96="" className="tablet-menu">
                                    <li data-v-1f10ba96="" className="tm-item">
                                        <div data-v-1f10ba96="" className="tm-item-name">Buy Crypto</div>
                                        <div data-v-1f10ba96="" className="tm-sub-item-wrap">
                                            <div data-v-1f10ba96="" className="tm-sub-item">
                                                <div data-v-1f10ba96=""
                                                     className="bx-nav-menu-base bx-nav-menu-buy-crypto"></div>
                                                <div data-v-1f10ba96="" className="tm-sub-info"><span data-v-1f10ba96=""
                                                                                                      className="tm-sub-name">Buy Crypto With Cards</span>
                                                    <span data-v-1f10ba96=""
                                                          className="tm-sub-desc">Visa, Mastercard, etc</span></div>
                                            </div>
                                            <div data-v-1f10ba96="" className="tm-sub-item">
                                                <div data-v-1f10ba96=""
                                                     className="bx-nav-menu-base bx-nav-menu-p2p-trading"></div>
                                                <div data-v-1f10ba96="" className="tm-sub-info"><span data-v-1f10ba96=""
                                                                                                      className="tm-sub-name">P2P</span>
                                                    <span data-v-1f10ba96="" className="tm-sub-desc">Multiple payment methods such as bank transfer are supported</span>
                                                </div>
                                            </div>
                                            <div data-v-1f10ba96="" className="tm-sub-item">
                                                <div data-v-1f10ba96=""
                                                     className="bx-nav-menu-base bx-nav-menu-bank-deposit"></div>
                                                <div data-v-1f10ba96="" className="tm-sub-info"><span data-v-1f10ba96=""
                                                                                                      className="tm-sub-name">Fiat Deposit</span>
                                                    <span data-v-1f10ba96="" className="tm-sub-desc">Deposit via bank transfer or online payments</span>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li data-v-1f10ba96="" className="tm-item">
                                        <div data-v-1f10ba96="" className="tm-item-name">Markets</div>
                                    </li>
                                    <li data-v-1f10ba96="" className="tm-item">
                                        <div data-v-1f10ba96="" className="tm-item-name">Spot</div>
                                        <div data-v-1f10ba96="" className="tm-sub-item-wrap">
                                            <div data-v-1f10ba96="" className="tm-sub-item">
                                                <div data-v-1f10ba96=""
                                                     className="bx-nav-menu-base bx-nav-menu-spot"></div>
                                                <div data-v-1f10ba96="" className="tm-sub-info"><span data-v-1f10ba96=""
                                                                                                      className="tm-sub-name">Spot</span>
                                                    <span data-v-1f10ba96="" className="tm-sub-desc">Trade hot assets in minutes</span>
                                                </div>
                                            </div>
                                            <div data-v-1f10ba96="" className="tm-sub-item">
                                                <div data-v-1f10ba96=""
                                                     className="bx-nav-menu-base bx-nav-menu-convert"></div>
                                                <div data-v-1f10ba96="" className="tm-sub-info"><span data-v-1f10ba96=""
                                                                                                      className="tm-sub-name">Convert</span>
                                                    <span data-v-1f10ba96="" className="tm-sub-desc">The easiest way to trade</span>
                                                </div>
                                            </div>
                                            <div data-v-1f10ba96="" className="tm-sub-item">
                                                <div data-v-1f10ba96=""
                                                     className="bx-nav-menu-base bx-nav-menu-launchpad"></div>
                                                <div data-v-1f10ba96="" className="tm-sub-info"><span data-v-1f10ba96=""
                                                                                                      className="tm-sub-name">Launchpool</span>
                                                    <span data-v-1f10ba96="" className="tm-sub-desc">Stake assets and mine effortlessly</span>
                                                </div>
                                            </div>
                                            <div data-v-1f10ba96="" className="tm-sub-item">
                                                <div data-v-1f10ba96=""
                                                     className="bx-nav-menu-base bx-nav-menu-prelaunchpool"></div>
                                                <div data-v-1f10ba96="" className="tm-sub-info"><span data-v-1f10ba96=""
                                                                                                      className="tm-sub-name">Pre-Launchpool</span>
                                                    <span data-v-1f10ba96="" className="tm-sub-desc">Stake assets and earn points for trending new tokens</span>
                                                </div>
                                            </div>
                                            <div data-v-1f10ba96="" className="tm-sub-item">
                                                <div data-v-1f10ba96=""
                                                     className="bx-nav-menu-base bx-nav-menu-wallet"></div>
                                                <div data-v-1f10ba96="" className="tm-sub-info"><span data-v-1f10ba96=""
                                                                                                      className="tm-sub-name">Solana Swap</span>
                                                    <span data-v-1f10ba96="" className="tm-sub-desc">Swap for hot tokens on Solana</span>
                                                </div>
                                            </div>
                                            <div data-v-1f10ba96="" className="tm-sub-item">
                                                <div data-v-1f10ba96=""
                                                     className="bx-nav-menu-base bx-nav-menu-pre-market"></div>
                                                <div data-v-1f10ba96="" className="tm-sub-info"><span data-v-1f10ba96=""
                                                                                                      className="tm-sub-name">Pre-Market Trading</span>
                                                    <span data-v-1f10ba96="" className="tm-sub-desc">New token OTC trading platform</span>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li data-v-1f10ba96="" className="tm-item">
                                        <div data-v-1f10ba96="" className="tm-item-name">Derivatives</div>
                                        <div data-v-1f10ba96="" className="tm-sub-item-wrap">
                                            <div data-v-1f10ba96="" className="tm-sub-item">
                                                <div data-v-1f10ba96=""
                                                     className="bx-nav-menu-base bx-nav-menu-swap"></div>
                                                <div data-v-1f10ba96="" className="tm-sub-info"><span data-v-1f10ba96=""
                                                                                                      className="tm-sub-name">USDⓢ-M Perp Futures</span>
                                                    <span data-v-1f10ba96="" className="tm-sub-desc">Advanced trading settled in USDⓢ</span>
                                                </div>
                                            </div>
                                            <div data-v-1f10ba96="" className="tm-sub-item">
                                                <div data-v-1f10ba96=""
                                                     className="bx-nav-menu-base bx-nav-menu-cswap"></div>
                                                <div data-v-1f10ba96="" className="tm-sub-info"><span data-v-1f10ba96=""
                                                                                                      className="tm-sub-name">Coin-M Perp Futures</span>
                                                    <span data-v-1f10ba96="" className="tm-sub-desc">Advanced trading using crypto as the margin</span>
                                                </div>
                                            </div>
                                            <div data-v-1f10ba96="" className="tm-sub-item">
                                                <div data-v-1f10ba96=""
                                                     className="bx-nav-menu-base bx-nav-menu-standard"></div>
                                                <div data-v-1f10ba96="" className="tm-sub-info"><span data-v-1f10ba96=""
                                                                                                      className="tm-sub-name">Standard Futures</span>
                                                    <span data-v-1f10ba96="" className="tm-sub-desc">Easy to use for ordinary investors</span>
                                                </div>
                                            </div>
                                            <div data-v-1f10ba96="" className="tm-sub-item">
                                                <div data-v-1f10ba96=""
                                                     className="bx-nav-menu-base bx-nav-menu-news"></div>
                                                <div data-v-1f10ba96="" className="tm-sub-info"><span data-v-1f10ba96=""
                                                                                                      className="tm-sub-name">Trading Information</span>
                                                    <span data-v-1f10ba96="" className="tm-sub-desc">View market info, trading guide, etc.</span>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li data-v-1f10ba96="" className="tm-item">
                                        <div data-v-1f10ba96="" className="tm-item-name">Copy Trading</div>
                                        <div data-v-1f10ba96="" className="tm-sub-item-wrap">
                                            <div data-v-1f10ba96="" className="tm-sub-item">
                                                <div data-v-1f10ba96=""
                                                     className="bx-nav-menu-base bx-nav-menu-copy-trading"></div>
                                                <div data-v-1f10ba96="" className="tm-sub-info"><span data-v-1f10ba96=""
                                                                                                      className="tm-sub-name">Copy Trading</span>
                                                    <span data-v-1f10ba96="" className="tm-sub-desc">Copy the strategies of elite traders</span>
                                                </div>
                                            </div>
                                            <div data-v-1f10ba96="" className="tm-sub-item">
                                                <div data-v-1f10ba96=""
                                                     className="bx-nav-menu-base bx-nav-menu-leadboard"></div>
                                                <div data-v-1f10ba96="" className="tm-sub-info"><span data-v-1f10ba96=""
                                                                                                      className="tm-sub-name">Leaderboard</span>
                                                    <span data-v-1f10ba96="" className="tm-sub-desc">Tap into the wisdom of global elites for max. profits</span>
                                                </div>
                                            </div>
                                            <div data-v-1f10ba96="" className="tm-sub-item">
                                                <div data-v-1f10ba96=""
                                                     className="bx-nav-menu-base bx-nav-menu-elite-traders"></div>
                                                <div data-v-1f10ba96="" className="tm-sub-info"><span data-v-1f10ba96=""
                                                                                                      className="tm-sub-name">Elite Traders Recruitment</span>
                                                    <span data-v-1f10ba96="" className="tm-sub-desc">Join the largest crypto trading community</span>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li data-v-1f10ba96="" className="tm-item">
                                        <div data-v-1f10ba96="" className="tm-item-name">Wealth</div>
                                        <div data-v-1f10ba96="" className="tm-sub-item-wrap">
                                            <div data-v-1f10ba96="" className="tm-sub-item">
                                                <div data-v-1f10ba96=""
                                                     className="bx-nav-menu-base bx-nav-menu-wealth-earn"></div>
                                                <div data-v-1f10ba96="" className="tm-sub-info"><span data-v-1f10ba96=""
                                                                                                      className="tm-sub-name">Earn</span>
                                                    <span data-v-1f10ba96="" className="tm-sub-desc">Secured principal and high returns with minimal risk</span>
                                                </div>
                                            </div>
                                            <div data-v-1f10ba96="" className="tm-sub-item">
                                                <div data-v-1f10ba96=""
                                                     className="bx-nav-menu-base bx-nav-menu-grid"></div>
                                                <div data-v-1f10ba96="" className="tm-sub-info"><span data-v-1f10ba96=""
                                                                                                      className="tm-sub-name">Grid Trading</span>
                                                    <span data-v-1f10ba96="" className="tm-sub-desc">Captures profitable opportunities automatically</span>
                                                </div>
                                            </div>
                                            <div data-v-1f10ba96="" className="tm-sub-item">
                                                <div data-v-1f10ba96=""
                                                     className="bx-nav-menu-base bx-nav-menu-wealth-loan"></div>
                                                <div data-v-1f10ba96="" className="tm-sub-info"><span data-v-1f10ba96=""
                                                                                                      className="tm-sub-name">Loan</span>
                                                    <span data-v-1f10ba96="" className="tm-sub-desc">Secure instant loans, repay anytime</span>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li data-v-1f10ba96="" className="tm-item">
                                        <div data-v-1f10ba96="" className="tm-item-name">Rewards Hub</div>
                                    </li>
                                    <li data-v-1f10ba96="" className="tm-item">
                                        <div data-v-1f10ba96="" className="tm-item-name">More</div>
                                        <div data-v-1f10ba96="" className="tm-sub-item-wrap">
                                            <div data-v-1f10ba96="" className="tm-sub-item tm-sub-item-three-menu">
                                                <div data-v-1f10ba96="" className="tm-sub-item-title">
                                                    Rewards
                                                </div>
                                            </div>
                                            <div data-v-1f10ba96="" className="tm-sub-item">
                                                <div data-v-1f10ba96=""
                                                     className="bx-nav-menu-base bx-nav-menu-invite-earn"></div>
                                                <div data-v-1f10ba96="" className="tm-sub-info"><span data-v-1f10ba96=""
                                                                                                      className="tm-sub-name">Invite to Earn</span>
                                                </div>
                                            </div>
                                            <div data-v-1f10ba96="" className="tm-sub-item">
                                                <div data-v-1f10ba96=""
                                                     className="bx-nav-menu-base bx-nav-menu-promotion-center"></div>
                                                <div data-v-1f10ba96="" className="tm-sub-info"><span data-v-1f10ba96=""
                                                                                                      className="tm-sub-name">Promotion Center</span>
                                                </div>
                                            </div>
                                            <div data-v-1f10ba96="" className="tm-sub-item">
                                                <div data-v-1f10ba96=""
                                                     className="bx-nav-menu-base bx-nav-menu-cards"></div>
                                                <div data-v-1f10ba96="" className="tm-sub-info"><span data-v-1f10ba96=""
                                                                                                      className="tm-sub-name">BingX Card</span>
                                                </div>
                                            </div>
                                            <div data-v-1f10ba96="" className="tm-sub-item tm-sub-item-three-menu">
                                                <div data-v-1f10ba96="" className="tm-sub-item-title">
                                                    BingX Academy
                                                </div>
                                            </div>
                                            <div data-v-1f10ba96="" className="tm-sub-item">
                                                <div data-v-1f10ba96=""
                                                     className="bx-nav-menu-base bx-nav-menu-help-center"></div>
                                                <div data-v-1f10ba96="" className="tm-sub-info"><span data-v-1f10ba96=""
                                                                                                      className="tm-sub-name">Help Center</span>
                                                </div>
                                            </div>
                                            <div data-v-1f10ba96="" className="tm-sub-item">
                                                <div data-v-1f10ba96=""
                                                     className="bx-nav-menu-base bx-nav-menu-glossary"></div>
                                                <div data-v-1f10ba96="" className="tm-sub-info"><span data-v-1f10ba96=""
                                                                                                      className="tm-sub-name">BingX Glossary</span>
                                                </div>
                                            </div>
                                            <div data-v-1f10ba96="" className="tm-sub-item">
                                                <div data-v-1f10ba96=""
                                                     className="bx-nav-menu-base bx-nav-menu-blog"></div>
                                                <div data-v-1f10ba96="" className="tm-sub-info"><span data-v-1f10ba96=""
                                                                                                      className="tm-sub-name">Blog</span>
                                                </div>
                                            </div>
                                            <div data-v-1f10ba96="" className="tm-sub-item tm-sub-item-three-menu">
                                                <div data-v-1f10ba96="" className="tm-sub-item-title">
                                                    Company
                                                </div>
                                            </div>
                                            <div data-v-1f10ba96="" className="tm-sub-item">
                                                <div data-v-1f10ba96=""
                                                     className="bx-nav-menu-base bx-nav-menu-100-proof"></div>
                                                <div data-v-1f10ba96="" className="tm-sub-info"><span data-v-1f10ba96=""
                                                                                                      className="tm-sub-name">100% Proof of Reserves</span>
                                                </div>
                                            </div>
                                            <div data-v-1f10ba96="" className="tm-sub-item">
                                                <div data-v-1f10ba96=""
                                                     className="bx-nav-menu-base bx-nav-menu-community"></div>
                                                <div data-v-1f10ba96="" className="tm-sub-info"><span data-v-1f10ba96=""
                                                                                                      className="tm-sub-name">BingX Community</span>
                                                </div>
                                            </div>
                                            <div data-v-1f10ba96="" className="tm-sub-item">
                                                <div data-v-1f10ba96=""
                                                     className="bx-nav-menu-base bx-nav-menu-about-us"></div>
                                                <div data-v-1f10ba96="" className="tm-sub-info"><span data-v-1f10ba96=""
                                                                                                      className="tm-sub-name">About Us</span>
                                                </div>
                                            </div>
                                            <div data-v-1f10ba96="" className="tm-sub-item">
                                                <div data-v-1f10ba96=""
                                                     className="bx-nav-menu-base bx-nav-menu-work-us"></div>
                                                <div data-v-1f10ba96="" className="tm-sub-info"><span data-v-1f10ba96=""
                                                                                                      className="tm-sub-name">Work With Us</span>
                                                </div>
                                            </div>
                                            <div data-v-1f10ba96="" className="tm-sub-item">
                                                <div data-v-1f10ba96=""
                                                     className="bx-nav-menu-base bx-nav-menu-news"></div>
                                                <div data-v-1f10ba96="" className="tm-sub-info"><span data-v-1f10ba96=""
                                                                                                      className="tm-sub-name">BingX News</span>
                                                </div>
                                            </div>
                                            <div data-v-1f10ba96="" className="tm-sub-item">
                                                <div data-v-1f10ba96=""
                                                     className="bx-nav-menu-base bx-nav-menu-ship-partner"></div>
                                                <div data-v-1f10ba96="" className="tm-sub-info"><span data-v-1f10ba96=""
                                                                                                      className="tm-sub-name">Partners</span>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div> : null}
                    </div>
                    <div className="nav-list" data-v-98383e2a="">
                        <div className="logo-split" data-v-98383e2a=""></div>
                        <ul className="menu-list" data-v-adf15ff4="" data-v-98383e2a="">
                            <li className="nav-item-wrap" data-v-7ba8058d="" data-v-adf15ff4="">
                                <div data-v-7ba8058d="" className="nav-item">
                                    <span data-v-7ba8058d="" className="nav-name">Buy Crypto</span>
                                </div>
                                <div data-v-7ba8058d="" className="sub-menus">
                                    <div data-v-7ba8058d="" className="sub-menus-inner">
                                        <div data-v-7ba8058d="" className="sub-menus-col">
                                            <div data-v-7ba8058d="" className="sub-menus-cell">
                                                <div data-v-7ba8058d=""
                                                     style={{maskImage: `url(${images.firstImage1})`}}
                                                     className="bx-nav-menu-base bx-nav-menu-buy-crypto"></div>
                                                <div data-v-7ba8058d="" className="menu-info">
                                                    <div data-v-7ba8058d="" className="name">
                                                        <span data-v-7ba8058d="">Quick Buy</span>
                                                        <span data-v-7ba8058d="" className="ic-brand"
                                                              style={{display: "none"}}>
                                                        </span>
                                                    </div>
                                                    <div data-v-7ba8058d="" className="desc">Visa, Mastercard, etc</div>
                                                </div>
                                                <div data-v-7ba8058d="" className="arrow bx-nav-svg-icon"></div>
                                            </div>
                                            <div data-v-7ba8058d="" className="sub-menus-cell">
                                                <div data-v-7ba8058d=""
                                                     style={{maskImage: `url(${images.firstImage2})`}}
                                                     className="bx-nav-menu-base bx-nav-menu-p2p-trading"></div>
                                                <div data-v-7ba8058d="" className="menu-info">
                                                    <div data-v-7ba8058d="" className="name"><span data-v-7ba8058d="">P2P Trading</span>
                                                        <span data-v-7ba8058d="" className="ic-brand"
                                                              style={{display: "none"}}></span></div>
                                                    <div data-v-7ba8058d="" className="desc">Multiple payment methods
                                                        such as bank transfer are supported
                                                    </div>
                                                </div>
                                                <div data-v-7ba8058d="" className="arrow bx-nav-svg-icon"></div>
                                            </div>
                                            <div data-v-7ba8058d="" className="sub-menus-cell">
                                                <div data-v-7ba8058d=""
                                                     style={{maskImage: `url(${images.firstImage3})`}}
                                                     className="bx-nav-menu-base bx-nav-menu-bank-deposit"></div>
                                                <div data-v-7ba8058d="" className="menu-info">
                                                    <div data-v-7ba8058d="" className="name"><span data-v-7ba8058d="">Fiat Deposit</span>
                                                        <span data-v-7ba8058d="" className="ic-brand promo"></span>
                                                    </div>
                                                    <div data-v-7ba8058d="" className="desc">Deposit via bank transfer
                                                        or online payments
                                                    </div>
                                                </div>
                                                <div data-v-7ba8058d="" className="arrow bx-nav-svg-icon"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li className="nav-item-wrap" data-v-7ba8058d="" data-v-adf15ff4="">
                                <a data-v-7ba8058d="" href="https://bingx.com/en/market/futures/" className="nav-item">
                                    <span data-v-7ba8058d="" className="nav-name">Markets</span>
                                </a>
                            </li>
                            <li className="nav-item-wrap" data-v-7ba8058d="" data-v-adf15ff4="">
                                <div className="nav-item" data-v-7ba8058d=""><span
                                    className="nav-name" data-v-7ba8058d="">Spot</span></div>
                                <div className="sub-menus" data-v-7ba8058d="">
                                    <div className="sub-menus-inner" data-v-7ba8058d="">
                                        <div className="sub-menus-col" data-v-7ba8058d="">
                                            <div data-v-7ba8058d="" className="sub-menus-cell">
                                                <div data-v-7ba8058d=""
                                                     style={{maskImage: `url(${images.secondImage1})`}}
                                                     className="bx-nav-menu-base bx-nav-menu-spot"></div>
                                                <div data-v-7ba8058d="" className="menu-info">
                                                    <div data-v-7ba8058d="" className="name"><span
                                                        data-v-7ba8058d="">Spot</span>
                                                        <span data-v-7ba8058d="" className="ic-brand"
                                                              style={{display: "none"}}></span>
                                                    </div>
                                                    <div data-v-7ba8058d="" className="desc">Trade hot assets in
                                                        minutes
                                                    </div>
                                                </div>
                                                <div data-v-7ba8058d="" className="arrow bx-nav-svg-icon"></div>
                                            </div>
                                            <div data-v-7ba8058d="" className="sub-menus-cell">
                                                <div data-v-7ba8058d=""
                                                     style={{maskImage: `url(${images.secondImage2})`}}
                                                     className="bx-nav-menu-base bx-nav-menu-convert"></div>
                                                <div data-v-7ba8058d="" className="menu-info">
                                                    <div data-v-7ba8058d="" className="name"><span
                                                        data-v-7ba8058d="">Convert</span> <span data-v-7ba8058d=""
                                                                                                className="ic-brand"
                                                                                                style={{display: "none"}}></span>
                                                    </div>
                                                    <div data-v-7ba8058d="" className="desc">The easiest way to trade
                                                    </div>
                                                </div>
                                                <div data-v-7ba8058d="" className="arrow bx-nav-svg-icon"></div>
                                            </div>
                                            <div data-v-7ba8058d="" className="sub-menus-cell">
                                                <div data-v-7ba8058d=""
                                                     style={{maskImage: `url(${images.secondImage3})`}}
                                                     className="bx-nav-menu-base bx-nav-menu-launchpad"></div>
                                                <div data-v-7ba8058d="" className="menu-info">
                                                    <div data-v-7ba8058d="" className="name"><span
                                                        data-v-7ba8058d="">Launchpool</span> <span data-v-7ba8058d=""
                                                                                                   className="ic-brand hot"></span>
                                                    </div>
                                                    <div data-v-7ba8058d="" className="desc">Stake assets and mine
                                                        effortlessly
                                                    </div>
                                                </div>
                                                <div data-v-7ba8058d="" className="arrow bx-nav-svg-icon"></div>
                                            </div>
                                            <div data-v-7ba8058d="" className="sub-menus-cell">
                                                <div data-v-7ba8058d=""
                                                     style={{maskImage: `url(${images.secondImage4})`}}
                                                     className="bx-nav-menu-base bx-nav-menu-prelaunchpool"></div>
                                                <div data-v-7ba8058d="" className="menu-info">
                                                    <div data-v-7ba8058d="" className="name"><span
                                                        data-v-7ba8058d="">Pre-Launchpool</span> <span
                                                        data-v-7ba8058d="" className="ic-brand"
                                                        style={{display: "none"}}></span></div>
                                                    <div data-v-7ba8058d="" className="desc">Stake assets and earn
                                                        points for trending new tokens
                                                    </div>
                                                </div>
                                                <div data-v-7ba8058d="" className="arrow bx-nav-svg-icon"></div>
                                            </div>
                                            <div data-v-7ba8058d="" className="sub-menus-cell">
                                                <div data-v-7ba8058d=""
                                                     style={{maskImage: `url(${images.secondImage5})`}}
                                                     className="bx-nav-menu-base bx-nav-menu-wallet"></div>
                                                <div data-v-7ba8058d="" className="menu-info">
                                                    <div data-v-7ba8058d="" className="name"><span data-v-7ba8058d="">Solana Swap</span>
                                                        <span data-v-7ba8058d="" className="ic-brand"
                                                              style={{display: "none"}}></span></div>
                                                    <div data-v-7ba8058d="" className="desc">Swap for hot tokens on
                                                        Solana
                                                    </div>
                                                </div>
                                                <div data-v-7ba8058d="" className="arrow bx-nav-svg-icon"></div>
                                            </div>
                                            <div data-v-7ba8058d="" className="sub-menus-cell">
                                                <div data-v-7ba8058d=""
                                                     style={{maskImage: `url(${images.secondImage6})`}}
                                                     className="bx-nav-menu-base bx-nav-menu-pre-market"></div>
                                                <div data-v-7ba8058d="" className="menu-info">
                                                    <div data-v-7ba8058d="" className="name"><span data-v-7ba8058d="">Pre-Market Trading</span>
                                                        <span data-v-7ba8058d="" className="ic-brand"
                                                              style={{display: "none"}}></span></div>
                                                    <div data-v-7ba8058d="" className="desc">New token OTC trading
                                                        platform
                                                    </div>
                                                </div>
                                                <div data-v-7ba8058d="" className="arrow bx-nav-svg-icon"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li className="nav-item-wrap" data-v-7ba8058d="" data-v-adf15ff4="">
                                <div className="nav-item" data-v-7ba8058d=""><span
                                    className="nav-name" data-v-7ba8058d="">Derivatives</span> <span data-v-7ba8058d=""
                                                                                                     className="ic-brand hot"></span>
                                </div>
                                <div className="sub-menus" data-v-7ba8058d="">
                                    <div className="sub-menus-inner" data-v-7ba8058d="">
                                        <div className="sub-menus-col" data-v-7ba8058d="">
                                            <div data-v-7ba8058d="" className="sub-menus-cell">
                                                <div data-v-7ba8058d=""
                                                     style={{maskImage: `url(${images.thirdImage1})`}}
                                                     className="bx-nav-menu-base bx-nav-menu-swap"></div>
                                                <div data-v-7ba8058d="" className="menu-info">
                                                    <div data-v-7ba8058d="" className="name"><span data-v-7ba8058d="">USDⓢ-M Perp Futures</span>
                                                        <span data-v-7ba8058d="" className="ic-brand"
                                                              style={{display: "none"}}></span></div>
                                                    <div data-v-7ba8058d="" className="desc">Advanced trading settled in
                                                        USDⓢ
                                                    </div>
                                                </div>
                                                <div data-v-7ba8058d="" className="arrow bx-nav-svg-icon"></div>
                                            </div>
                                            <div data-v-7ba8058d="" className="sub-menus-cell">
                                                <div data-v-7ba8058d=""
                                                     style={{maskImage: `url(${images.thirdImage2})`}}
                                                     className="bx-nav-menu-base bx-nav-menu-cswap"></div>
                                                <div data-v-7ba8058d="" className="menu-info">
                                                    <div data-v-7ba8058d="" className="name"><span data-v-7ba8058d="">Coin-M Perp Futures</span>
                                                        <span data-v-7ba8058d="" className="ic-brand new"></span></div>
                                                    <div data-v-7ba8058d="" className="desc">Advanced trading using
                                                        crypto as the margin
                                                    </div>
                                                </div>
                                                <div data-v-7ba8058d="" className="arrow bx-nav-svg-icon"></div>
                                            </div>
                                            <div data-v-7ba8058d="" className="sub-menus-cell">
                                                <div data-v-7ba8058d=""
                                                     style={{maskImage: `url(${images.thirdImage3})`}}
                                                     className="bx-nav-menu-base bx-nav-menu-standard"></div>
                                                <div data-v-7ba8058d="" className="menu-info">
                                                    <div data-v-7ba8058d="" className="name"><span data-v-7ba8058d="">Standard Futures</span>
                                                        <span data-v-7ba8058d="" className="ic-brand"
                                                              style={{display: "none"}}></span></div>
                                                    <div data-v-7ba8058d="" className="desc">Easy to use for ordinary
                                                        investors
                                                    </div>
                                                </div>
                                                <div data-v-7ba8058d="" className="arrow bx-nav-svg-icon"></div>
                                            </div>
                                            <div data-v-7ba8058d="" className="sub-menus-cell dropdown"
                                                 style={{display: "none"}}>
                                                <div data-v-7ba8058d=""

                                                     className="bx-nav-menu-base bx-nav-menu-news"></div>
                                                <div data-v-7ba8058d="" className="menu-info">
                                                    <div data-v-7ba8058d="" className="name"><span data-v-7ba8058d="">Trading Information</span>
                                                        <span data-v-7ba8058d="" className="ic-brand"
                                                              style={{display: "none"}}></span></div>
                                                    <div data-v-7ba8058d="" className="desc">View market info, trading
                                                        guide, etc.
                                                    </div>
                                                </div>
                                                <div data-v-7ba8058d="" className="arrow bx-nav-svg-icon"></div>
                                                <div data-v-7ba8058d="" className="sub-menus-dropdown">
                                                    <div data-v-7ba8058d="" className="sub-menus-inner"
                                                         style={{cursor: "default"}}>
                                                        <div data-v-7ba8058d="" className="sub-menus-cell-wrapper">
                                                            <div data-v-7ba8058d="" className="title-pre">Perpetual
                                                                Futures
                                                            </div>
                                                            <div data-v-7ba8058d="" className="sub-menus-cell">
                                                                <div data-v-7ba8058d="" className="menu-info">
                                                                    <div data-v-7ba8058d="" className="name"><span
                                                                        data-v-7ba8058d="">Contract Info</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div data-v-7ba8058d=""
                                                             className="sub-menus-cell-wrapper">
                                                            <div data-v-7ba8058d="" className="sub-menus-cell">
                                                                <div data-v-7ba8058d="" className="menu-info">
                                                                    <div data-v-7ba8058d="" className="name"><span
                                                                        data-v-7ba8058d="">Trading Rules</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div data-v-7ba8058d=""
                                                             className="sub-menus-cell-wrapper">
                                                            <div data-v-7ba8058d="" className="sub-menus-cell">
                                                                <div data-v-7ba8058d="" className="menu-info">
                                                                    <div data-v-7ba8058d="" className="name"><span
                                                                        data-v-7ba8058d="">Position &amp; Leverage</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div data-v-7ba8058d=""
                                                             className="sub-menus-cell-wrapper">
                                                            <div data-v-7ba8058d="" className="sub-menus-cell">
                                                                <div data-v-7ba8058d="" className="menu-info">
                                                                    <div data-v-7ba8058d="" className="name"><span
                                                                        data-v-7ba8058d="">Position &amp; Maintenance Margin Rate</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div data-v-7ba8058d=""
                                                             className="sub-menus-cell-wrapper">
                                                            <div data-v-7ba8058d="" className="sub-menus-cell">
                                                                <div data-v-7ba8058d="" className="menu-info">
                                                                    <div data-v-7ba8058d="" className="name"><span
                                                                        data-v-7ba8058d="">Funding Rate History</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div data-v-7ba8058d=""
                                                             className="sub-menus-cell-wrapper">
                                                            <div data-v-7ba8058d="" className="sub-menus-cell">
                                                                <div data-v-7ba8058d="" className="menu-info">
                                                                    <div data-v-7ba8058d="" className="name"><span
                                                                        data-v-7ba8058d="">GTD Price Fee Rate</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div data-v-7ba8058d=""
                                                             className="sub-menus-cell-wrapper">
                                                            <div data-v-7ba8058d="" className="sub-menus-cell">
                                                                <div data-v-7ba8058d="" className="menu-info">
                                                                    <div data-v-7ba8058d="" className="name"><span
                                                                        data-v-7ba8058d="">Help Center</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div data-v-7ba8058d="" className="sub-menus-inner">
                                                        <div data-v-7ba8058d="" className="sub-menus-cell-wrapper">
                                                            <div data-v-7ba8058d="" className="title-pre">Standard
                                                                Futures
                                                            </div>
                                                            <div data-v-7ba8058d="" className="sub-menus-cell">
                                                                <div data-v-7ba8058d="" className="menu-info">
                                                                    <div data-v-7ba8058d="" className="name"><span
                                                                        data-v-7ba8058d="">Leverage &amp; Position Limit</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div data-v-7ba8058d=""
                                                             className="sub-menus-cell-wrapper">
                                                            <div data-v-7ba8058d="" className="sub-menus-cell">
                                                                <div data-v-7ba8058d="" className="menu-info">
                                                                    <div data-v-7ba8058d="" className="name"><span
                                                                        data-v-7ba8058d="">Adjustment Factor</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div data-v-7ba8058d=""
                                                             className="sub-menus-cell-wrapper">
                                                            <div data-v-7ba8058d="" className="sub-menus-cell">
                                                                <div data-v-7ba8058d="" className="menu-info">
                                                                    <div data-v-7ba8058d="" className="name"><span
                                                                        data-v-7ba8058d="">Funding Rate History</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div data-v-7ba8058d=""
                                                             className="sub-menus-cell-wrapper">
                                                            <div data-v-7ba8058d="" className="sub-menus-cell">
                                                                <div data-v-7ba8058d="" className="menu-info">
                                                                    <div data-v-7ba8058d="" className="name"><span
                                                                        data-v-7ba8058d="">Help Center</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li className="nav-item-wrap" data-v-7ba8058d="" data-v-adf15ff4="">
                                <div className="nav-item" data-v-7ba8058d=""><span
                                    className="nav-name" data-v-7ba8058d="">Copy Trading</span></div>
                                <div className="sub-menus" data-v-7ba8058d="">
                                    <div className="sub-menus-inner" data-v-7ba8058d="">
                                        <div className="sub-menus-col" data-v-7ba8058d="">
                                            <div data-v-7ba8058d="" className="sub-menus-cell">
                                                <div data-v-7ba8058d=""
                                                     style={{maskImage: `url(${images.fourImage1})`}}
                                                     className="bx-nav-menu-base bx-nav-menu-copy-trading"></div>
                                                <div data-v-7ba8058d="" className="menu-info">
                                                    <div data-v-7ba8058d="" className="name"><span data-v-7ba8058d="">Copy Trading</span>
                                                        <span data-v-7ba8058d="" className="ic-brand"
                                                              style={{display: "none"}}></span></div>
                                                    <div data-v-7ba8058d="" className="desc">Copy the strategies of
                                                        elite traders
                                                    </div>
                                                </div>
                                                <div data-v-7ba8058d="" className="arrow bx-nav-svg-icon"></div>
                                            </div>
                                            <div data-v-7ba8058d="" className="sub-menus-cell">
                                                <div data-v-7ba8058d=""
                                                     style={{maskImage: `url(${images.fourImage2})`}}
                                                     className="bx-nav-menu-base bx-nav-menu-leadboard"></div>
                                                <div data-v-7ba8058d="" className="menu-info">
                                                    <div data-v-7ba8058d="" className="name"><span
                                                        data-v-7ba8058d="">Leaderboard</span> <span data-v-7ba8058d=""
                                                                                                    className="ic-brand"
                                                                                                    style={{display: "none"}}></span>
                                                    </div>
                                                    <div data-v-7ba8058d="" className="desc">Tap into the wisdom of
                                                        global elites for max. profits
                                                    </div>
                                                </div>
                                                <div data-v-7ba8058d="" className="arrow bx-nav-svg-icon"></div>
                                            </div>
                                            <div data-v-7ba8058d="" className="sub-menus-cell">
                                                <div data-v-7ba8058d=""
                                                     style={{maskImage: `url(${images.fourImage3})`}}
                                                     className="bx-nav-menu-base bx-nav-menu-elite-traders"></div>
                                                <div data-v-7ba8058d="" className="menu-info">
                                                    <div data-v-7ba8058d="" className="name"><span data-v-7ba8058d="">Elite Traders Recruitment</span>
                                                        <span data-v-7ba8058d="" className="ic-brand"
                                                              style={{display: "none"}}></span></div>
                                                    <div data-v-7ba8058d="" className="desc">Join the largest crypto
                                                        trading community
                                                    </div>
                                                </div>
                                                <div data-v-7ba8058d="" className="arrow bx-nav-svg-icon"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li data-v-7ba8058d="" data-v-adf15ff4="" className="nav-item-wrap">
                                <div data-v-7ba8058d="" className="nav-item"><span data-v-7ba8058d=""
                                                                                   className="nav-name">Wealth</span>
                                </div>
                                <div data-v-7ba8058d="" className="sub-menus">
                                    <div data-v-7ba8058d="" className="sub-menus-inner">
                                        <div data-v-7ba8058d="" className="sub-menus-col">
                                            <div data-v-7ba8058d="" className="sub-menus-cell">
                                                <div data-v-7ba8058d=""
                                                     style={{maskImage: `url(${images.fiveImage1})`}}
                                                     className="bx-nav-menu-base bx-nav-menu-wealth-earn"></div>
                                                <div data-v-7ba8058d="" className="menu-info">
                                                    <div data-v-7ba8058d="" className="name"><span
                                                        data-v-7ba8058d="">Earn</span> <span data-v-7ba8058d=""
                                                                                             className="ic-brand"
                                                                                             style={{display: "none"}}></span>
                                                    </div>
                                                    <div data-v-7ba8058d="" className="desc">Secured principal and high
                                                        returns with minimal risk
                                                    </div>
                                                </div>
                                                <div data-v-7ba8058d="" className="arrow bx-nav-svg-icon"></div>
                                            </div>
                                            <div data-v-7ba8058d="" className="sub-menus-cell">
                                                <div data-v-7ba8058d=""
                                                     style={{maskImage: `url(${images.fiveImage2})`}}
                                                     className="bx-nav-menu-base bx-nav-menu-grid"></div>
                                                <div data-v-7ba8058d="" className="menu-info">
                                                    <div data-v-7ba8058d="" className="name"><span data-v-7ba8058d="">Grid Trading</span>
                                                        <span data-v-7ba8058d="" className="ic-brand"
                                                              style={{display: "none"}}></span></div>
                                                    <div data-v-7ba8058d="" className="desc">Captures profitable
                                                        opportunities automatically
                                                    </div>
                                                </div>
                                                <div data-v-7ba8058d="" className="arrow bx-nav-svg-icon"></div>
                                            </div>
                                            <div data-v-7ba8058d="" className="sub-menus-cell">
                                                <div data-v-7ba8058d=""
                                                     style={{maskImage: `url(${images.fiveImage3})`}}
                                                     className="bx-nav-menu-base bx-nav-menu-wealth-loan"></div>
                                                <div data-v-7ba8058d="" className="menu-info">
                                                    <div data-v-7ba8058d="" className="name"><span
                                                        data-v-7ba8058d="">Loan</span> <span data-v-7ba8058d=""
                                                                                             className="ic-brand new"></span>
                                                    </div>
                                                    <div data-v-7ba8058d="" className="desc">Secure instant loans, repay
                                                        anytime
                                                    </div>
                                                </div>
                                                <div data-v-7ba8058d="" className="arrow bx-nav-svg-icon"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li data-v-7ba8058d="" data-v-adf15ff4="" className="nav-item-wrap"><a data-v-7ba8058d=""
                                                                                                   href="https://bingx.com/en/rewards/"
                                                                                                   className="nav-item"><span
                                data-v-7ba8058d="" className="nav-name">Rewards Hub</span> </a></li>
                            <li data-v-7ba8058d="" data-v-adf15ff4="" className="nav-item-wrap">
                                <div data-v-7ba8058d="" className="nav-item"><span data-v-7ba8058d=""
                                                                                   className="nav-name">More</span>
                                </div>
                                <div data-v-7ba8058d="" className="sub-menus multi-cols">
                                    <div data-v-7ba8058d="" className="sub-menus-inner">
                                        <div data-v-7ba8058d="" className="sub-menus-col">
                                            <div data-v-7ba8058d="" className="cate-title">Rewards</div>
                                            <div data-v-7ba8058d="" className="sub-menus-cell">
                                                <div data-v-7ba8058d=""
                                                     style={{maskImage: `url(${images.six1Image1})`}}
                                                     className="bx-nav-menu-base bx-nav-menu-vip-benefits"></div>
                                                <div data-v-7ba8058d="" className="menu-info">
                                                    <div data-v-7ba8058d="" className="name"><span data-v-7ba8058d="">VIP Benefits</span>
                                                        <span data-v-7ba8058d="" className="ic-brand"
                                                              style={{display: "none"}}></span></div>
                                                    <div data-v-7ba8058d="" className="desc"
                                                         style={{display: "none"}}></div>
                                                </div>
                                                <div data-v-7ba8058d="" className="arrow bx-nav-svg-icon"></div>
                                            </div>
                                            <div data-v-7ba8058d="" className="sub-menus-cell">
                                                <div data-v-7ba8058d=""
                                                     style={{maskImage: `url(${images.six1Image2})`}}
                                                     className="bx-nav-menu-base bx-nav-menu-invite-earn"></div>
                                                <div data-v-7ba8058d="" className="menu-info">
                                                    <div data-v-7ba8058d="" className="name"><span data-v-7ba8058d="">Invite to Earn</span>
                                                        <span data-v-7ba8058d="" className="ic-brand"
                                                              style={{display: "none"}}></span></div>
                                                    <div data-v-7ba8058d="" className="desc"
                                                         style={{display: "none"}}></div>
                                                </div>
                                                <div data-v-7ba8058d="" className="arrow bx-nav-svg-icon"></div>
                                            </div>
                                            <div data-v-7ba8058d="" className="sub-menus-cell">
                                                <div data-v-7ba8058d=""
                                                     style={{maskImage: `url(${images.six1Image3})`}}
                                                     className="bx-nav-menu-base bx-nav-menu-promotion-center"></div>
                                                <div data-v-7ba8058d="" className="menu-info">
                                                    <div data-v-7ba8058d="" className="name"><span data-v-7ba8058d="">Promotion Center</span>
                                                        <span data-v-7ba8058d="" className="ic-brand"
                                                              style={{display: "none"}}></span></div>
                                                    <div data-v-7ba8058d="" className="desc"
                                                         style={{display: "none"}}></div>
                                                </div>
                                                <div data-v-7ba8058d="" className="arrow bx-nav-svg-icon"></div>
                                            </div>
                                            <div data-v-7ba8058d="" className="sub-menus-cell">
                                                <div data-v-7ba8058d=""
                                                     style={{maskImage: `url(${images.six1Image4})`}}
                                                     className="bx-nav-menu-base bx-nav-menu-cards"></div>
                                                <div data-v-7ba8058d="" className="menu-info">
                                                    <div data-v-7ba8058d="" className="name"><span data-v-7ba8058d="">BingX Card</span>
                                                        <span data-v-7ba8058d="" className="ic-brand"
                                                              style={{display: "none"}}></span></div>
                                                    <div data-v-7ba8058d="" className="desc"
                                                         style={{display: "none"}}></div>
                                                </div>
                                                <div data-v-7ba8058d="" className="arrow bx-nav-svg-icon"></div>
                                            </div>
                                        </div>
                                        <div data-v-7ba8058d="" className="sub-menus-col">
                                            <div data-v-7ba8058d="" className="cate-title">BingX Academy</div>
                                            <div data-v-7ba8058d="" className="sub-menus-cell">
                                                <div data-v-7ba8058d=""
                                                     style={{maskImage: `url(${images.six2Image1})`}}
                                                     className="bx-nav-menu-base bx-nav-menu-academy"></div>
                                                <div data-v-7ba8058d="" className="menu-info">
                                                    <div data-v-7ba8058d="" className="name"><span data-v-7ba8058d="">BingX Academy</span>
                                                        <span data-v-7ba8058d="" className="ic-brand"
                                                              style={{display: "none"}}></span></div>
                                                    <div data-v-7ba8058d="" className="desc"
                                                         style={{display: "none"}}></div>
                                                </div>
                                                <div data-v-7ba8058d="" className="arrow bx-nav-svg-icon"></div>
                                            </div>
                                            <div data-v-7ba8058d="" className="sub-menus-cell">
                                                <div data-v-7ba8058d=""
                                                     style={{maskImage: `url(${images.six2Image2})`}}
                                                     className="bx-nav-menu-base bx-nav-menu-help-center"></div>
                                                <div data-v-7ba8058d="" className="menu-info">
                                                    <div data-v-7ba8058d="" className="name"><span data-v-7ba8058d="">Help Center</span>
                                                        <span data-v-7ba8058d="" className="ic-brand"
                                                              style={{display: "none"}}></span></div>
                                                    <div data-v-7ba8058d="" className="desc"
                                                         style={{display: "none"}}></div>
                                                </div>
                                                <div data-v-7ba8058d="" className="arrow bx-nav-svg-icon"></div>
                                            </div>
                                            <div data-v-7ba8058d="" className="sub-menus-cell">
                                                <div data-v-7ba8058d=""
                                                     style={{maskImage: `url(${images.six2Image3})`}}
                                                     className="bx-nav-menu-base bx-nav-menu-glossary"></div>
                                                <div data-v-7ba8058d="" className="menu-info">
                                                    <div data-v-7ba8058d="" className="name"><span data-v-7ba8058d="">BingX Glossary</span>
                                                        <span data-v-7ba8058d="" className="ic-brand"
                                                              style={{display: "none"}}></span></div>
                                                    <div data-v-7ba8058d="" className="desc"
                                                         style={{display: "none"}}></div>
                                                </div>
                                                <div data-v-7ba8058d="" className="arrow bx-nav-svg-icon"></div>
                                            </div>
                                            <div data-v-7ba8058d="" className="sub-menus-cell">
                                                <div data-v-7ba8058d=""
                                                     style={{maskImage: `url(${images.six2Image4})`}}
                                                     className="bx-nav-menu-base bx-nav-menu-blog"></div>
                                                <div data-v-7ba8058d="" className="menu-info">
                                                    <div data-v-7ba8058d="" className="name"><span
                                                        data-v-7ba8058d="">Blog</span> <span data-v-7ba8058d=""
                                                                                             className="ic-brand"
                                                                                             style={{display: "none"}}></span>
                                                    </div>
                                                    <div data-v-7ba8058d="" className="desc"
                                                         style={{display: "none"}}></div>
                                                </div>
                                                <div data-v-7ba8058d="" className="arrow bx-nav-svg-icon"></div>
                                            </div>
                                        </div>
                                        <div data-v-7ba8058d="" className="sub-menus-col">
                                            <div data-v-7ba8058d="" className="cate-title">Company</div>
                                            <div data-v-7ba8058d="" className="sub-menus-cell">
                                                <div data-v-7ba8058d=""
                                                     style={{maskImage: `url(${images.six3Image1})`}}
                                                     className="bx-nav-menu-base bx-nav-menu-affiliate"></div>
                                                <div data-v-7ba8058d="" className="menu-info">
                                                    <div data-v-7ba8058d="" className="name"><span data-v-7ba8058d="">BingX Affiliate Program</span>
                                                        <span data-v-7ba8058d="" className="ic-brand"
                                                              style={{display: "none"}}></span></div>
                                                    <div data-v-7ba8058d="" className="desc"
                                                         style={{display: "none"}}></div>
                                                </div>
                                                <div data-v-7ba8058d="" className="arrow bx-nav-svg-icon"></div>
                                            </div>
                                            <div data-v-7ba8058d="" className="sub-menus-cell">
                                                <div data-v-7ba8058d=""
                                                     style={{maskImage: `url(${images.six3Image2})`}}
                                                     className="bx-nav-menu-base bx-nav-menu-100-proof"></div>
                                                <div data-v-7ba8058d="" className="menu-info">
                                                    <div data-v-7ba8058d="" className="name"><span data-v-7ba8058d="">100% Proof of Reserves</span>
                                                        <span data-v-7ba8058d="" className="ic-brand"
                                                              style={{display: "none"}}></span></div>
                                                    <div data-v-7ba8058d="" className="desc"
                                                         style={{display: "none"}}></div>
                                                </div>
                                                <div data-v-7ba8058d="" className="arrow bx-nav-svg-icon"></div>
                                            </div>
                                            <div data-v-7ba8058d="" className="sub-menus-cell">
                                                <div data-v-7ba8058d=""
                                                     style={{maskImage: `url(${images.six3Image3})`}}
                                                     className="bx-nav-menu-base bx-nav-menu-community"></div>
                                                <div data-v-7ba8058d="" className="menu-info">
                                                    <div data-v-7ba8058d="" className="name"><span data-v-7ba8058d="">BingX Community</span>
                                                        <span data-v-7ba8058d="" className="ic-brand"
                                                              style={{display: "none"}}></span></div>
                                                    <div data-v-7ba8058d="" className="desc"
                                                         style={{display: "none"}}></div>
                                                </div>
                                                <div data-v-7ba8058d="" className="arrow bx-nav-svg-icon"></div>
                                            </div>
                                            <div data-v-7ba8058d="" className="sub-menus-cell">
                                                <div data-v-7ba8058d=""
                                                     style={{maskImage: `url(${images.six3Image4})`}}
                                                     className="bx-nav-menu-base bx-nav-menu-about-us"></div>
                                                <div data-v-7ba8058d="" className="menu-info">
                                                    <div data-v-7ba8058d="" className="name"><span data-v-7ba8058d="">About Us</span>
                                                        <span data-v-7ba8058d="" className="ic-brand"
                                                              style={{display: "none"}}></span></div>
                                                    <div data-v-7ba8058d="" className="desc"
                                                         style={{display: "none"}}></div>
                                                </div>
                                                <div data-v-7ba8058d="" className="arrow bx-nav-svg-icon"></div>
                                            </div>
                                            <div data-v-7ba8058d="" className="sub-menus-cell">
                                                <div data-v-7ba8058d=""
                                                     style={{maskImage: `url(${images.six3Image5})`}}
                                                     className="bx-nav-menu-base bx-nav-menu-work-us"></div>
                                                <div data-v-7ba8058d="" className="menu-info">
                                                    <div data-v-7ba8058d="" className="name"><span data-v-7ba8058d="">Work With Us</span>
                                                        <span data-v-7ba8058d="" className="ic-brand"
                                                              style={{display: "none"}}></span></div>
                                                    <div data-v-7ba8058d="" className="desc"
                                                         style={{display: "none"}}></div>
                                                </div>
                                                <div data-v-7ba8058d="" className="arrow bx-nav-svg-icon"></div>
                                            </div>
                                            <div data-v-7ba8058d="" className="sub-menus-cell">
                                                <div data-v-7ba8058d=""
                                                     style={{maskImage: `url(${images.six3Image6})`}}
                                                     className="bx-nav-menu-base bx-nav-menu-news"></div>
                                                <div data-v-7ba8058d="" className="menu-info">
                                                    <div data-v-7ba8058d="" className="name"><span data-v-7ba8058d="">BingX News</span>
                                                        <span data-v-7ba8058d="" className="ic-brand"
                                                              style={{display: "none"}}></span></div>
                                                    <div data-v-7ba8058d="" className="desc"
                                                         style={{display: "none"}}></div>
                                                </div>
                                                <div data-v-7ba8058d="" className="arrow bx-nav-svg-icon"></div>
                                            </div>
                                            <div data-v-7ba8058d="" className="sub-menus-cell">
                                                <div data-v-7ba8058d=""
                                                     style={{maskImage: `url(${images.six3Image7})`}}
                                                     className="bx-nav-menu-base bx-nav-menu-ship-partner"></div>
                                                <div data-v-7ba8058d="" className="menu-info">
                                                    <div data-v-7ba8058d="" className="name"><span
                                                        data-v-7ba8058d="">Partners</span>
                                                        <span data-v-7ba8058d="" className="ic-brand"
                                                              style={{display: "none"}}></span>
                                                    </div>
                                                    <div data-v-7ba8058d="" className="desc"
                                                         style={{display: "none"}}></div>
                                                </div>
                                                <div data-v-7ba8058d="" className="arrow bx-nav-svg-icon"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="nav-right" data-v-98383e2a="">
                    <div className="login-bar" data-v-98383e2a="">
                        <a
                            href="https://bingx.com/en/login/" className="btn-login" data-v-98383e2a="">
                            Log In
                        </a>
                        <a href="https://bingx.com/en/register/" className="btn-reg"
                           data-v-98383e2a="">
                            Sign Up
                        </a>
                    </div>
                    <a style={{display: "none"}} data-v-98383e2a="">
                        <div className="action-icon bx-nav-svg-icon h5-assets-icon" data-v-98383e2a=""></div>
                    </a> <a style={{display: "none"}} data-v-98383e2a="">
                    <div className="action-icon bx-nav-svg-icon h5-info-icon" data-v-98383e2a=""></div>
                </a>
                    <div className="sub-menu right-item ic-assets h5-hide" style={{display: "none"}} data-v-c71deb24=""
                         data-v-98383e2a="">
                        <div data-v-c71deb24="" data-v-98383e2a="">Assets</div>
                        <div data-v-c71deb24="" className="popover-menu-wrapper">
                            <div data-v-c71deb24="" className="popover-menu">
                                <div data-v-5bfb40e1="" data-v-98383e2a="" className="assets-menus common-scroll-bar"
                                     data-v-c71deb24="">
                                    <div data-v-5bfb40e1="" className="asset">
                                        <div data-v-5bfb40e1="" className="ics-arrow-ct">
                                            <i data-v-331d72b6="" data-v-5bfb40e1=""
                                               className="bx-svg-warpper ics-arrow rtl-element-reverse"
                                               style={{height: "20px"}}><i data-v-331d72b6="" className="bx-svg-inner"
                                                                           style={{width: "20px", height: "20px"}}></i></i>
                                        </div>
                                        <div data-v-5bfb40e1="" className="title">
                                            Total Assets
                                            <i data-v-331d72b6="" data-v-5bfb40e1=""
                                               className="bx-svg-warpper ic-asset-eyes" style={{height: "16px"}}><i
                                                data-v-331d72b6="" className="bx-svg-inner"
                                                style={{width: "16px", height: "16px"}}/></i>
                                        </div>
                                        <div data-v-5bfb40e1="" className="amount rtl-text-ltr"><span data-v-5bfb40e1=""
                                                                                                      className="number">--</span>
                                            <span data-v-5bfb40e1="" className="unit">USDT</span></div>
                                        <div data-v-5bfb40e1="" className="fiat rtl-text-ta-right row"><span
                                            data-v-5bfb40e1="" className="symbol">≈ ֏</span></div>
                                    </div>
                                    <div data-v-5bfb40e1="" className="tips">
                                        *Data updates may be delayed
                                    </div>

                                    <div data-v-5bfb40e1="" className="menu">
                                        <div data-v-5bfb40e1="">
                                            <div data-v-5bfb40e1="">
                                                <div data-v-5bfb40e1="" className="label">Accounts</div>
                                            </div>
                                        </div>
                                        <div data-v-5bfb40e1="">
                                            <div data-v-5bfb40e1="">
                                                <div data-v-5bfb40e1="" className="asset-account-item"><i
                                                    data-v-331d72b6="" data-v-5bfb40e1=""
                                                    className="bx-svg-warpper asset-account-icon"
                                                    style={{height: "20px"}}><i
                                                    data-v-331d72b6="" className="bx-svg-inner"
                                                    style={{width: "20px", height: "20px"}}/></i>
                                                    <span data-v-5bfb40e1="" className="name">Fund Account</span></div>
                                            </div>
                                        </div>
                                        <div data-v-5bfb40e1="">
                                            <div data-v-5bfb40e1="">
                                                <div data-v-5bfb40e1="" className="asset-account-item"><i
                                                    data-v-331d72b6="" data-v-5bfb40e1=""
                                                    className="bx-svg-warpper asset-account-icon"
                                                    style={{height: "20px"}}><i
                                                    data-v-331d72b6="" className="bx-svg-inner"
                                                    style={{width: "20px", height: "20px"}}/></i>
                                                    <span data-v-5bfb40e1="" className="name">Futures Account</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div data-v-5bfb40e1="">
                                            <div data-v-5bfb40e1="">
                                                <div data-v-5bfb40e1="" className="asset-account-item"><i
                                                    data-v-331d72b6="" data-v-5bfb40e1=""
                                                    className="bx-svg-warpper asset-account-icon"
                                                    style={{height: "20px"}}><i
                                                    data-v-331d72b6="" className="bx-svg-inner"
                                                    style={{width: "20px", height: "20px"}}></i></i>
                                                    <span data-v-5bfb40e1=""
                                                          className="name">Copy Trading Account</span></div>
                                            </div>
                                        </div>
                                        <div data-v-5bfb40e1="">
                                            <div data-v-5bfb40e1="">
                                                <div data-v-5bfb40e1="" className="asset-account-item"><i
                                                    data-v-331d72b6="" data-v-5bfb40e1=""
                                                    className="bx-svg-warpper asset-account-icon"
                                                    style={{height: "20px"}}><i
                                                    data-v-331d72b6="" className="bx-svg-inner"
                                                    style={{width: "20px", height: "20px"}}/></i>
                                                    <span data-v-5bfb40e1="" className="name">Grid Account</span></div>
                                            </div>
                                        </div>
                                        <div data-v-5bfb40e1="">
                                            <div data-v-5bfb40e1="">
                                                <div data-v-5bfb40e1="" className="asset-account-item"><i
                                                    data-v-331d72b6="" data-v-5bfb40e1=""
                                                    className="bx-svg-warpper asset-account-icon"
                                                    style={{height: "20px"}}><i
                                                    data-v-331d72b6="" className="bx-svg-inner"
                                                    style={{width: "20px", height: "20px"}}/></i>
                                                    <span data-v-5bfb40e1="" className="name">Wealth Account</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div data-v-5bfb40e1="">
                                            <div data-v-5bfb40e1="">
                                                <div data-v-5bfb40e1="" className="label">Records</div>
                                            </div>
                                        </div>
                                        <div data-v-5bfb40e1="">
                                            <div data-v-5bfb40e1="">
                                                <div data-v-5bfb40e1="" className="asset-account-item"><i
                                                    data-v-331d72b6="" data-v-5bfb40e1=""
                                                    className="bx-svg-warpper asset-account-icon"
                                                    style={{height: "20px"}}><i
                                                    data-v-331d72b6="" className="bx-svg-inner"
                                                    style={{width: "20px", height: "20px"}}></i></i>
                                                    <span data-v-5bfb40e1="" className="name">Asset Records</span></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="sub-menu right-item h5-hide" style={{display: "none"}} data-v-c71deb24=""
                         data-v-98383e2a="">
                        <div className="action-item" data-v-c71deb24="" data-v-98383e2a="">
                            <div className="action-icon ic-notification bx-nav-svg-icon" data-v-c71deb24=""
                                 data-v-98383e2a=""></div>
                            <div className="brand" style={{display: "none"}} data-v-c71deb24="" data-v-98383e2a="">
                                0
                            </div>
                        </div>
                        <div data-v-c71deb24="" className="popover-menu-wrapper">
                            <div data-v-c71deb24="" className="popover-menu">
                                <div data-v-98164aea="" data-v-98383e2a="" className="notification-menu"
                                     data-v-c71deb24="">
                                    <div data-v-98164aea="" className="group-title">
                                        <div data-v-98164aea="" className="group-title-txt-wrap"><span
                                            data-v-98164aea="">Message Center</span> <i data-v-98164aea=""
                                                                                        className="ic-read-wrapper">
                                            <i
                                                data-v-331d72b6="" data-v-98164aea="" className="bx-svg-warpper icon"
                                                style={{height: "14px"}}><i data-v-331d72b6="" className="bx-svg-inner"
                                                                            style={{
                                                                                width: "14px",
                                                                                height: "14px"
                                                                            }}/></i></i>
                                        </div>
                                        <span data-v-98164aea="" className="view-all">
      View All
      <i data-v-331d72b6="" data-v-98164aea="" className="bx-svg-warpper view-all-icon" style={{height: "16px"}}><i
          data-v-331d72b6="" className="bx-svg-inner rtl-reserve"
          style={{width: "16px", height: "16px"}}/></i></span>
                                    </div>
                                    <div data-v-98164aea="" className="bind-mail-tips-item">
                                        <div data-v-98164aea="" className="bind-mail-tips"><i data-v-98164aea=""
                                                                                              className="tips"/>
                                            <span
                                                data-v-98164aea="">Link email to receive timely order notifications &gt;&gt;</span>
                                            <i data-v-98164aea="" className="close"/></div>
                                    </div>
                                    <div data-v-98164aea="" className="notification-scrollbar">
                                        <div data-v-98164aea="" className="loading-ct">
                                            <div data-v-6dfd8dc0="" data-v-98164aea="" className="loading-svg">
                                                <svg data-v-6dfd8dc0="" version="1.1" width="30px" height="30px"
                                                     viewBox="0 0 30 30" fill="var(--color-blue)">
                                                    <rect data-v-6dfd8dc0="" x="5" y="10" width="4" height="10"
                                                          opacity="0.2">
                                                        <animate data-v-6dfd8dc0="" attributeName="opacity"
                                                                 attributeType="XML" values="0.2; 1; .2" begin="0s"
                                                                 dur="0.6s" repeatCount="indefinite"></animate>
                                                        <animate data-v-6dfd8dc0="" attributeName="height"
                                                                 attributeType="XML" values="10; 20; 10" begin="0s"
                                                                 dur="0.6s" repeatCount="indefinite"></animate>
                                                        <animate data-v-6dfd8dc0="" attributeName="y"
                                                                 attributeType="XML" values="10; 5; 10" begin="0s"
                                                                 dur="0.6s" repeatCount="indefinite"></animate>
                                                    </rect>
                                                    <rect
                                                        data-v-6dfd8dc0="" x="13" y="10" width="4" height="10"
                                                        opacity="0.2">
                                                        <animate data-v-6dfd8dc0="" attributeName="opacity"
                                                                 attributeType="XML" values="0.2; 1; .2" begin="0.15s"
                                                                 dur="0.6s" repeatCount="indefinite"></animate>
                                                        <animate data-v-6dfd8dc0="" attributeName="height"
                                                                 attributeType="XML" values="10; 20; 10" begin="0.15s"
                                                                 dur="0.6s" repeatCount="indefinite"></animate>
                                                        <animate data-v-6dfd8dc0="" attributeName="y"
                                                                 attributeType="XML"
                                                                 values="10; 5; 10" begin="0.15s" dur="0.6s"
                                                                 repeatCount="indefinite"></animate>
                                                    </rect>
                                                    <rect
                                                        data-v-6dfd8dc0="" x="21" y="10" width="4" height="10"
                                                        opacity="0.2">
                                                        <animate data-v-6dfd8dc0="" attributeName="opacity"
                                                                 attributeType="XML" values="0.2; 1; .2" begin="0.3s"
                                                                 dur="0.6s" repeatCount="indefinite"></animate>
                                                        <animate data-v-6dfd8dc0="" attributeName="height"
                                                                 attributeType="XML" values="10; 20; 10" begin="0.3s"
                                                                 dur="0.6s" repeatCount="indefinite"></animate>
                                                        <animate data-v-6dfd8dc0="" attributeName="y"
                                                                 attributeType="XML"
                                                                 values="10; 5; 10" begin="0.3s" dur="0.6s"
                                                                 repeatCount="indefinite"></animate>
                                                    </rect>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="sub-menu right-item h5-hide" style={{display: "none"}} data-v-c71deb24=""
                         data-v-98383e2a="">
                        <div className="action-item" data-v-c71deb24="" data-v-98383e2a="">
                            <div className="action-icon ic-account bx-nav-svg-icon" data-v-c71deb24=""
                                 data-v-98383e2a=""></div>
                        </div>
                        <div data-v-c71deb24="" className="popover-menu-wrapper">
                            <div data-v-c71deb24="" className="popover-menu">
                                <div data-v-3614e7b8="" data-v-98383e2a="" className="account-menus common-scroll-bar"
                                     data-v-c71deb24="">
                                    <div data-v-3614e7b8="" className="account-info">
                                        <div data-v-3614e7b8=""><p data-v-3614e7b8="" className="avatar-text"></p></div>
                                        <div data-v-3614e7b8="" className="account-right"><p data-v-3614e7b8=""
                                                                                             className="account"></p>
                                            <p data-v-3614e7b8="" className="uid"> <span data-v-3614e7b8=""
                                                                                         className="uid-part">
          UID:
          <svg data-v-3614e7b8="" width="16" height="16" viewBox="0 0 16 16" fill="none"
               xmlns="http://www.w3.org/2000/svg" className="user-uid"><rect data-v-3614e7b8="" x="2.5" y="5"
                                                                             width="8.5" height="8.5"
                                                                             stroke="var(--text-3)" stroke-width="1.2"
                                                                             stroke-linejoin="round"></rect> <path
              data-v-3614e7b8="" d="M5.5 3.59375V2H14V10.5H12.4062" stroke="var(--text-3)" stroke-width="1.2"
              stroke-linejoin="round"></path></svg></span></p>
                                        </div>
                                    </div>

                                    <div data-v-3614e7b8="" className="vip-grade-wrap">
                                        <div data-v-3614e7b8="" className="vip-grade"><img data-v-3614e7b8=""
                                                                                           src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMTciIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0ibTEwLjIxNyA4LjA0Mi0xLjctMS43YS44MDIuODAyIDAgMCAwLTEuMTM0IDEuMTMzTDkuNjUgOS43NDNhLjgwMi44MDIgMCAwIDAgMS4xMzQgMGwyLjI2OC0yLjI2OGEuODAxLjgwMSAwIDEgMC0xLjEzNC0xLjEzNGwtMS43IDEuNzAxWk0xNC45OTEuNjk3bDQuNzA3IDUuMzY0YTEuMTM0IDEuMTM0IDAgMCAxLS4wMjcgMS41MjVsLTguODEzIDguNTU2YTEuMTM0IDEuMTM0IDAgMCAxLTEuNjUgMEwuMzk1IDcuNTg3YTEuMTM0IDEuMTM0IDAgMCAxLS4wMjgtMS41MjVMNS4wNzQuNjk3QTEuMTM0IDEuMTM0IDAgMCAxIDUuOTI3LjMxaDguMjExYTEuMTMzIDEuMTMzIDAgMCAxIC44NTIuMzg2aC4wMDFaIiBmaWxsPSIjMkE1NEZGIi8+PC9zdmc+"/>
                                            <span data-v-3614e7b8="">VIP 0</span></div>
                                        <div data-v-3614e7b8="" className="vip-to"></div>
                                    </div>
                                    <ul data-v-4ba8b90e="" data-v-3614e7b8=""
                                        className="bx-nav-base-menu menu-list menu-list">
                                        <li data-v-4ba8b90e="" className="menu-item"><span
                                            data-v-4ba8b90e="">Account &amp; Security</span></li>
                                        <li data-v-4ba8b90e="" className="menu-item"><span data-v-4ba8b90e="">Identity Verification</span>
                                        </li>
                                        <li data-v-4ba8b90e="" className="menu-item"><span data-v-4ba8b90e="">Invite to Earn</span>
                                        </li>
                                        <li data-v-4ba8b90e="" className="menu-item"><span data-v-4ba8b90e="">My Vouchers <span
                                            className="amount">≈ --</span></span></li>
                                        <li data-v-4ba8b90e="" className="menu-item"><span
                                            data-v-4ba8b90e="">My Events</span></li>
                                        <li data-v-4ba8b90e="" className="menu-item"><span data-v-4ba8b90e="">My Copy Trading</span>
                                        </li>
                                        <li data-v-4ba8b90e="" className="menu-item"><span data-v-4ba8b90e="">Become a Trader</span>
                                        </li>
                                        <li data-v-4ba8b90e="" className="menu-item"><span data-v-4ba8b90e="">Purchase Record</span>
                                        </li>
                                        <li data-v-4ba8b90e="" className="menu-item"><span data-v-4ba8b90e="">API Management</span>
                                        </li>
                                        <li data-v-4ba8b90e="" className="menu-item"><span data-v-4ba8b90e="">Sub Accounts</span>
                                        </li>
                                        <li data-v-4ba8b90e="" className="menu-item"><span
                                            data-v-4ba8b90e="">Preferences</span></li>
                                        <li data-v-4ba8b90e="" className="menu-item red"><span data-v-4ba8b90e="">Log Out</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="global-setting" data-v-98383e2a="">
                        <div className="sub-menu right-item h5-hide" data-v-c71deb24="" data-v-98383e2a="">
                            <div className="action-item" data-v-c71deb24="" data-v-98383e2a="">
                                <div style={{maskImage: `url(${Down})`}}
                                     className="action-icon ic-download bx-nav-svg-icon" data-v-c71deb24=""
                                     data-v-98383e2a=""></div>
                            </div>
                            <div data-v-c71deb24="" className="popover-menu-wrapper">
                                <div data-v-c71deb24="" className="popover-menu">
                                    <div data-v-3a261801="" data-v-98383e2a=""
                                         className="bx-nav-base-menu app-qrcode-wrapper" data-v-c71deb24="">
                                        <div data-v-3a261801="" className="channel-info"><a data-v-3a261801=""
                                                                                            href="https://apps.apple.com/us/app/bingbon-global/id1500217666?ls=1"
                                                                                            target="_blank"
                                                                                            title="AppStore"
                                                                                            rel="noopener"
                                                                                            className="link">
                                            <div data-v-3a261801="" className="channel-item"><span data-v-3a261801=""
                                                                                                   className="device icon-app-store"></span>
                                                <span data-v-3a261801="" className="name">App Store</span></div>
                                        </a> <a data-v-3a261801=""
                                                href="https://play.google.com/store/apps/details?id=pro.bingbon.app"
                                                target="_blank" title="GooglePlay" rel="noopener" className="link">
                                            <div data-v-3a261801="" className="channel-item"><span data-v-3a261801=""
                                                                                                   className="device icon-google-play"></span>
                                                <span data-v-3a261801="" className="name">Google Play</span></div>
                                        </a></div>
                                        <div data-v-3a261801="" className="qr-code-wrapper">
                                            <div data-v-3a261801="" className="code"><img data-v-3a261801="" width="150"
                                                                                          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAfQAAAH0AQMAAADxGE3JAAAABlBMVEX///8AAABVwtN+AAACkklEQVR42u3dO3LjMBCEYbAcKNQRfBQeTTzaHmWP4NCBSrPEbIMNms4gF+WqvyPuYj46GgMEHy6EkBPzFl3+lLiXTD24xprHenytAzV/yy2cTzweP+o/JLJszUVl68Acy1TLsjAPblGUCY/HP8PP2Y3ps+w9Ir3KNKD+VccveDz+1Xzcy6WOlsxcDy7dxIzH43/Ku3+XXP/qRHmAx+N/xiv2Xf2jXLLCXsHj8S/gHc2/Wub6+vXaD2zB4/Gj/hitf9/yfLf/ZXPI74LH48d9tqn7N8u0/5P+GrEtezXR5vyJx+PHvKfJPFFRthuZHtD8qZ8YgcfjX8Fn2mMGuv4sym79W9MmZjweP+w9/2Yj52jI941d4/4teDx+3LdufOg2iUfjy/6P4gcL8Hj8+b5dpu73j+Jeme+feP/2rt8HeDx+1Lt/s/67bSI19uqVFHg8fti7zLdJXNYq1rh/JfB4/Jh3/4rl7Pju6894tIPoH+yZs8PxePzJfv+al9+/DJ+4HmR04mtkPvF4/JDX/JvxMnfrzvT5E+SX0vp3wuPxT/KTtlW3Mr8mEtV7Ys2OzxMXPB5/rj8+fxC7bdpD/9dC7R/h8fgBL6Z8+LbILTSwpb2YuQk8Hj/sff/S/+39H9X33x+QXyY8Hj/q81/eZs02zSQ7nHjpZ1w8Hn+u99VkK/NtEmfunz+QwOPxI/4YXX/6xmaZYo2/X7ebsfF4/IA/fP/Dn3lV2da28hqYI/B4/At479/6+wX9wrglBywmPB4/6t2Nhz9TkAP2x/UvHo9/pvdjdvaXbv1rsRQ8Hv9kH47Xr7XCH2b2+8t4PP58r1Tf4u+nr34XPxhU8Hj8qHf6v7+l87l/NdA/KITH4wc8IeQX5x8CpNH3nlzXkgAAAABJRU5ErkJggg=="
                                                                                          alt=""/></div>
                                            <p data-v-3a261801="" className="download-tips">iOS &amp; Android
                                                Download</p></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="fiat-switch" data-v-223c5cdb="" data-v-98383e2a="">
                            <div style={{maskImage: `url(${World})`}} className="fiat-switch-icon bx-nav-svg-icon"
                                 data-v-223c5cdb=""></div>
                        </div>
                        <div className="theme-switch" data-v-66c1fb23="" data-v-98383e2a="">
                            <div style={{maskImage: `url(${Moon})`}} className="icon-theme bx-nav-svg-icon light"
                                 data-v-66c1fb23=""></div>
                        </div>
                    </div>
                    <div onClick={()=> setOpenMobile(!openMobile)} className={openMobile ? "drawer-btn drawer-btn_close" : "drawer-btn"} data-v-98383e2a=""></div>
                </div>
            </nav>
            {openMobile ?
                <div data-v-b669f826="" data-v-98383e2a="" className="drawer-wrapper">
                    <ul data-v-b669f826="" className="drawer-content">
                        <li data-v-667f498d="" data-v-b669f826="" className="menu-item-wrapper dropdown">
                            <div onClick={()=>setByCryptoOpen(!byCryptoOpen)} data-v-667f498d="" className="menu-item">
                                <span data-v-667f498d="" className="label">Buy Crypto</span>
                                <span data-v-667f498d="" className="arrow-down"></span>
                            </div>
                            <div data-v-667f498d="" className="sub-menus"  style={byCryptoOpen ? {display:"block"} : {display: "none"}}>
                                <a data-v-667f498d=""
                                                                                                    target="_blank"
                                                                                                    href="https://bingx.paycat.com/en/"
                                                                                                    className="sub-menu">
                                <div data-v-667f498d="" className="bx-nav-menu-base bx-nav-menu-buy-crypto"></div>
                                <div data-v-667f498d="" className="menu-info"><span data-v-667f498d="" className="name">Buy Crypto With Cards</span>
                                    <span data-v-667f498d="" className="desc">Visa, Mastercard, etc</span></div>
                                <div data-v-667f498d="" className="arrow-right"></div>
                            </a><a data-v-667f498d="" target="_blank"
                                   href="https://bingx.paycat.com/en/trade/self-selection/" className="sub-menu">
                                <div data-v-667f498d="" className="bx-nav-menu-base bx-nav-menu-p2p-trading"></div>
                                <div data-v-667f498d="" className="menu-info"><span data-v-667f498d=""
                                                                                    className="name">P2P</span> <span
                                    data-v-667f498d="" className="desc">Multiple payment methods such as bank transfer are supported</span>
                                </div>
                                <div data-v-667f498d="" className="arrow-right"></div>
                            </a><a data-v-667f498d="" target="_blank"
                                   href="https://bingx.paycat.com/en/trade/bankTransfer/?from=nav" className="sub-menu">
                                <div data-v-667f498d="" className="bx-nav-menu-base bx-nav-menu-bank-deposit"></div>
                                <div data-v-667f498d="" className="menu-info"><span data-v-667f498d="" className="name">Fiat Deposit</span>
                                    <span data-v-667f498d=""
                                          className="desc">Deposit via bank transfer or online payments</span></div>
                                <div data-v-667f498d="" className="arrow-right"></div>
                            </a></div>
                        </li>
                        <li data-v-667f498d="" data-v-b669f826="" className="menu-item-wrapper route"><a
                            data-v-667f498d=""
                            href="https://bingx.com/en/market/futures/"
                            className="menu-item link"><span
                            data-v-667f498d="">Markets</span> </a></li>
                        <li data-v-667f498d="" data-v-b669f826="" className="menu-item-wrapper dropdown">
                            <div onClick={()=>setSpotOpen(!spotOpen)} data-v-667f498d="" className="menu-item"><span data-v-667f498d="" className="label">Spot</span> <span data-v-667f498d="" className="arrow-down"></span></div>
                            <div data-v-667f498d="" className="sub-menus"  style={spotOpen ? {display:"block"} : {display: "none"}}><a data-v-667f498d=""
                                                                                                    href="https://bingx.com/en/spot/BTCUSDT/"
                                                                                                    className="sub-menu">
                                <div data-v-667f498d="" className="bx-nav-menu-base bx-nav-menu-spot"></div>
                                <div data-v-667f498d="" className="menu-info"><span data-v-667f498d=""
                                                                                    className="name">Spot</span> <span
                                    data-v-667f498d="" className="desc">Trade hot assets in minutes</span></div>
                                <div data-v-667f498d="" className="arrow-right"></div>
                            </a><a data-v-667f498d="" href="https://bingx.com/en/exchange/" className="sub-menu">
                                <div data-v-667f498d="" className="bx-nav-menu-base bx-nav-menu-convert"></div>
                                <div data-v-667f498d="" className="menu-info"><span data-v-667f498d=""
                                                                                    className="name">Convert</span>
                                    <span
                                        data-v-667f498d="" className="desc">The easiest way to trade</span></div>
                                <div data-v-667f498d="" className="arrow-right"></div>
                            </a><a data-v-667f498d="" target="_blank" href="https://bingx.com/en/launchpool/overview/"
                                   className="sub-menu">
                                <div data-v-667f498d="" className="bx-nav-menu-base bx-nav-menu-launchpad"></div>
                                <div data-v-667f498d="" className="menu-info"><span data-v-667f498d=""
                                                                                    className="name">Launchpool</span>
                                    <span
                                        data-v-667f498d="" className="desc">Stake assets and mine effortlessly</span>
                                </div>
                                <div data-v-667f498d="" className="arrow-right"></div>
                            </a><a data-v-667f498d="" target="_blank"
                                   href="https://bingx.com/en/pre-launchpool/overview/"
                                   className="sub-menu">
                                <div data-v-667f498d="" className="bx-nav-menu-base bx-nav-menu-prelaunchpool"></div>
                                <div data-v-667f498d="" className="menu-info"><span data-v-667f498d=""
                                                                                    className="name">Pre-Launchpool</span>
                                    <span data-v-667f498d="" className="desc">Stake assets and earn points for trending new tokens</span>
                                </div>
                                <div data-v-667f498d="" className="arrow-right"></div>
                            </a><a data-v-667f498d="" target="_blank" href="https://bingx.com/en/web3/"
                                   className="sub-menu">
                                <div data-v-667f498d="" className="bx-nav-menu-base bx-nav-menu-wallet"></div>
                                <div data-v-667f498d="" className="menu-info"><span data-v-667f498d="" className="name">Solana Swap</span>
                                    <span data-v-667f498d="" className="desc">Swap for hot tokens on Solana</span></div>
                                <div data-v-667f498d="" className="arrow-right"></div>
                            </a><a data-v-667f498d="" target="_blank" href="https://bingx.com/en/spot/pre-market/"
                                   className="sub-menu">
                                <div data-v-667f498d="" className="bx-nav-menu-base bx-nav-menu-pre-market"></div>
                                <div data-v-667f498d="" className="menu-info"><span data-v-667f498d="" className="name">Pre-Market Trading</span>
                                    <span data-v-667f498d="" className="desc">New token OTC trading platform</span>
                                </div>
                                <div data-v-667f498d="" className="arrow-right"></div>
                            </a></div>
                        </li>
                        <li data-v-667f498d="" data-v-b669f826="" className="menu-item-wrapper dropdown">
                            <div onClick={()=>SetDerivativesOpen(!derivativesOpen)} data-v-667f498d="" className="menu-item"><span data-v-667f498d="" className="label">Derivatives<span data-v-667f498d="" className="ic-brand hot hot"></span></span> <span data-v-667f498d=""
                                                                                   className="arrow-down"></span></div>
                            <div data-v-667f498d="" className="sub-menus"  style={derivativesOpen ? {display:"block"} : {display: "none"}}><a data-v-667f498d=""
                                                                                                    target="_blank"
                                                                                                    href="https://swap.bingx.com/en/BTC-USDT/"
                                                                                                    className="sub-menu">
                                <div data-v-667f498d="" className="bx-nav-menu-base bx-nav-menu-swap"></div>
                                <div data-v-667f498d="" className="menu-info"><span data-v-667f498d="" className="name">USDⓢ-M Perp Futures</span>
                                    <span data-v-667f498d="" className="desc">Advanced trading settled in USDⓢ</span>
                                </div>
                                <div data-v-667f498d="" className="arrow-right"></div>
                            </a><a data-v-667f498d="" target="_blank" href="https://swap.bingx.com/en/BTC-USD/"
                                   className="sub-menu">
                                <div data-v-667f498d="" className="bx-nav-menu-base bx-nav-menu-cswap"></div>
                                <div data-v-667f498d="" className="menu-info"><span data-v-667f498d="" className="name">Coin-M Perp Futures</span>
                                    <span data-v-667f498d=""
                                          className="desc">Advanced trading using crypto as the margin</span></div>
                                <div data-v-667f498d="" className="arrow-right"></div>
                            </a><a data-v-667f498d="" href="https://bingx.com/en/futures/forward/BTCUSDT/" className="sub-menu">
                                <div data-v-667f498d="" className="bx-nav-menu-base bx-nav-menu-standard"></div>
                                <div data-v-667f498d="" className="menu-info"><span data-v-667f498d="" className="name">Standard Futures</span>
                                    <span data-v-667f498d="" className="desc">Easy to use for ordinary investors</span>
                                </div>
                                <div data-v-667f498d="" className="arrow-right"></div>
                            </a></div>
                        </li>
                        <li data-v-667f498d="" data-v-b669f826="" className="menu-item-wrapper dropdown">
                            <div onClick={()=>setCopyTradingOpen(!copyTradingOpen)} data-v-667f498d="" className="menu-item"><span data-v-667f498d="" className="label">
        Copy Trading
                             </span> <span data-v-667f498d="" className="arrow-down"></span></div>
                            <div data-v-667f498d="" className="sub-menus"  style={copyTradingOpen ? {display:"block"} : {display: "none"}}><a data-v-667f498d=""
                                                                                                    href="https://bingx.com/en/CopyTrading/?from=5"
                                                                                                    className="sub-menu">
                                <div data-v-667f498d="" className="bx-nav-menu-base bx-nav-menu-copy-trading"></div>
                                <div data-v-667f498d="" className="menu-info"><span data-v-667f498d="" className="name">Copy Trading</span>
                                    <span data-v-667f498d=""
                                          className="desc">Copy the strategies of elite traders</span>
                                </div>
                                <div data-v-667f498d="" className="arrow-right"></div>
                            </a><a data-v-667f498d="" href="https://bingx.com/en/CopyTrading/leaderBoard/" className="sub-menu">
                                <div data-v-667f498d="" className="bx-nav-menu-base bx-nav-menu-leadboard"></div>
                                <div data-v-667f498d="" className="menu-info"><span data-v-667f498d=""
                                                                                    className="name">Leaderboard</span>
                                    <span data-v-667f498d="" className="desc">Tap into the wisdom of global elites for max. profits</span>
                                </div>
                                <div data-v-667f498d="" className="arrow-right"></div>
                            </a><a data-v-667f498d="" href="https://bingx.com/en/CopyTrading/recruiting/" className="sub-menu">
                                <div data-v-667f498d="" className="bx-nav-menu-base bx-nav-menu-elite-traders"></div>
                                <div data-v-667f498d="" className="menu-info"><span data-v-667f498d="" className="name">Elite Traders Recruitment</span>
                                    <span data-v-667f498d=""
                                          className="desc">Join the largest crypto trading community</span></div>
                                <div data-v-667f498d="" className="arrow-right"></div>
                            </a></div>
                        </li>
                        <li data-v-667f498d="" data-v-b669f826="" className="menu-item-wrapper dropdown">
                            <div onClick={()=>setWealthOpen(!wealthOpen)} data-v-667f498d="" className="menu-item"><span data-v-667f498d="" className="label">
        Wealth
                             </span> <span data-v-667f498d="" className="arrow-down"></span></div>
                            <div data-v-667f498d="" className="sub-menus"  style={wealthOpen ? {display:"block"} : {display: "none"}}><a data-v-667f498d=""
                                                                                                    target="_blank"
                                                                                                    href="https://bingx.com/en/wealth/overview/"
                                                                                                    className="sub-menu">
                                <div data-v-667f498d="" className="bx-nav-menu-base bx-nav-menu-wealth-earn"></div>
                                <div data-v-667f498d="" className="menu-info"><span data-v-667f498d=""
                                                                                    className="name">Earn</span> <span
                                    data-v-667f498d=""
                                    className="desc">Secured principal and high returns with minimal risk</span></div>
                                <div data-v-667f498d="" className="arrow-right"></div>
                            </a><a data-v-667f498d="" href="https://bingx.com/en/strategy/" className="sub-menu">
                                <div data-v-667f498d="" className="bx-nav-menu-base bx-nav-menu-grid"></div>
                                <div data-v-667f498d="" className="menu-info"><span data-v-667f498d="" className="name">Grid Trading</span>
                                    <span data-v-667f498d="" className="desc">Captures profitable opportunities automatically</span>
                                </div>
                                <div data-v-667f498d="" className="arrow-right"></div>
                            </a><a data-v-667f498d="" target="_blank"
                                   href="https://bingx.com/en/wealth/crypto-loans/borrow/" className="sub-menu">
                                <div data-v-667f498d="" className="bx-nav-menu-base bx-nav-menu-wealth-loan"></div>
                                <div data-v-667f498d="" className="menu-info"><span data-v-667f498d=""
                                                                                    className="name">Loan</span> <span
                                    data-v-667f498d="" className="desc">Secure instant loans, repay anytime</span></div>
                                <div data-v-667f498d="" className="arrow-right"></div>
                            </a></div>
                        </li>
                        <li data-v-667f498d="" data-v-b669f826="" className="menu-item-wrapper link"><a
                            data-v-667f498d="" target="_self" href="https://bingx.com/en/rewards/"
                            
                            className="menu-item link"><span data-v-667f498d="">Rewards Hub</span> </a></li>
                        <li data-v-667f498d="" data-v-b669f826="" className="menu-item-wrapper dropdown">
                            <div onClick={()=>setMoreOpen(!moreOpen)} data-v-667f498d="" className="menu-item"><span data-v-667f498d="" className="label">
        More
                             </span> <span data-v-667f498d="" className="arrow-down"></span></div>
                            <div data-v-667f498d="" className="sub-menus"  style={moreOpen ? {display:"block"} : {display: "none"}}><a data-v-667f498d=""
                                                                                                    target="_blank"
                                                                                                    href="https://bingx.com/en/vip/introduction/"
                                                                                                    className="sub-menu">
                                <div data-v-667f498d="" className="bx-nav-menu-base bx-nav-menu-vip-benefits"></div>
                                <div data-v-667f498d="" className="menu-info"><span data-v-667f498d="" className="name">VIP Benefits</span>
                                </div>
                                <div data-v-667f498d="" className="arrow-right"></div>
                            </a><a data-v-667f498d="" href="https://bingx.com/en/login/?redirect=%2Fen%2Finvite%2F" className="sub-menu">
                                <div data-v-667f498d="" className="bx-nav-menu-base bx-nav-menu-invite-earn"></div>
                                <div data-v-667f498d="" className="menu-info"><span data-v-667f498d="" className="name">Invite to Earn</span>
                                </div>
                                <div data-v-667f498d="" className="arrow-right"></div>
                            </a><a data-v-667f498d="" href="https://bingx.com/en/events/" className="sub-menu">
                                <div data-v-667f498d="" className="bx-nav-menu-base bx-nav-menu-promotion-center"></div>
                                <div data-v-667f498d="" className="menu-info"><span data-v-667f498d="" className="name">Promotion Center</span>
                                </div>
                                <div data-v-667f498d="" className="arrow-right"></div>
                            </a><a data-v-667f498d="" href="https://bingx.com/en/cards/" className="sub-menu">
                                <div data-v-667f498d="" className="bx-nav-menu-base bx-nav-menu-cards"></div>
                                <div data-v-667f498d="" className="menu-info"><span data-v-667f498d="" className="name">BingX Card</span>
                                </div>
                                <div data-v-667f498d="" className="arrow-right"></div>
                            </a><a data-v-667f498d="" href="https://bingx.com/en/guide/" className="sub-menu">
                                <div data-v-667f498d="" className="bx-nav-menu-base bx-nav-menu-academy"></div>
                                <div data-v-667f498d="" className="menu-info"><span data-v-667f498d="" className="name">BingX Academy</span>
                                </div>
                                <div data-v-667f498d="" className="arrow-right"></div>
                            </a><a data-v-667f498d="" href="https://bingx.com/en/support/" className="sub-menu">
                                <div data-v-667f498d="" className="bx-nav-menu-base bx-nav-menu-help-center"></div>
                                <div data-v-667f498d="" className="menu-info"><span data-v-667f498d="" className="name">Help Center</span>
                                </div>
                                <div data-v-667f498d="" className="arrow-right"></div>
                            </a><a data-v-667f498d="" target="_blank" href="https://bingx.com/en/wiki/"
                                   className="sub-menu">
                                <div data-v-667f498d="" className="bx-nav-menu-base bx-nav-menu-glossary"></div>
                                <div data-v-667f498d="" className="menu-info"><span data-v-667f498d="" className="name">BingX Glossary</span>
                                </div>
                                <div data-v-667f498d="" className="arrow-right"></div>
                            </a><a data-v-667f498d="" target="_blank" href="https://blog.bingx.com"
                                   className="sub-menu">
                                <div data-v-667f498d="" className="bx-nav-menu-base bx-nav-menu-blog"></div>
                                <div data-v-667f498d="" className="menu-info"><span data-v-667f498d=""
                                                                                    className="name">Blog</span>
                                </div>
                                <div data-v-667f498d="" className="arrow-right"></div>
                            </a><a data-v-667f498d="" href="https://bingx.com/en/global-partner/?origin_type=hometop"
                                   className="sub-menu">
                                <div data-v-667f498d="" className="bx-nav-menu-base bx-nav-menu-affiliate"></div>
                                <div data-v-667f498d="" className="menu-info"><span data-v-667f498d="" className="name">BingX Affiliate Program</span>
                                </div>
                                <div data-v-667f498d="" className="arrow-right"></div>
                            </a><a data-v-667f498d="" href="https://bingx.com/en/balance-reserve/" className="sub-menu">
                                <div data-v-667f498d="" className="bx-nav-menu-base bx-nav-menu-100-proof"></div>
                                <div data-v-667f498d="" className="menu-info"><span data-v-667f498d="" className="name">100% Proof of Reserves</span>
                                </div>
                                <div data-v-667f498d="" className="arrow-right"></div>
                            </a><a data-v-667f498d="" href="https://bingx.com/en/community/" className="sub-menu">
                                <div data-v-667f498d="" className="bx-nav-menu-base bx-nav-menu-community"></div>
                                <div data-v-667f498d="" className="menu-info"><span data-v-667f498d="" className="name">BingX Community</span>
                                </div>
                                <div data-v-667f498d="" className="arrow-right"></div>
                            </a><a data-v-667f498d="" href="https://bingx.com/en/about/" className="sub-menu">
                                <div data-v-667f498d="" className="bx-nav-menu-base bx-nav-menu-about-us"></div>
                                <div data-v-667f498d="" className="menu-info"><span data-v-667f498d="" className="name">About Us</span>
                                </div>
                                <div data-v-667f498d="" className="arrow-right"></div>
                            </a><a data-v-667f498d="" href="https://bingx.com/en/careers/" className="sub-menu">
                                <div data-v-667f498d="" className="bx-nav-menu-base bx-nav-menu-work-us"></div>
                                <div data-v-667f498d="" className="menu-info"><span data-v-667f498d="" className="name">Work With Us</span>
                                </div>
                                <div data-v-667f498d="" className="arrow-right"></div>
                            </a><a data-v-667f498d="" target="_blank"
                                   href="https://blog.bingx.com/category/announcement/events/" className="sub-menu">
                                <div data-v-667f498d="" className="bx-nav-menu-base bx-nav-menu-news"></div>
                                <div data-v-667f498d="" className="menu-info"><span data-v-667f498d="" className="name">BingX News</span>
                                </div>
                                <div data-v-667f498d="" className="arrow-right"></div>
                            </a><a data-v-667f498d="" href="https://bingx.com/en/sponsorship/landing/" className="sub-menu">
                                <div data-v-667f498d="" className="bx-nav-menu-base bx-nav-menu-ship-partner"></div>
                                <div data-v-667f498d="" className="menu-info"><span data-v-667f498d=""
                                                                                    className="name">Partners</span>
                                </div>
                                <div data-v-667f498d="" className="arrow-right"></div>
                            </a></div>
                        </li>
                    </ul>
                    <div data-v-b669f826="" className="user-operation-wrapper"><a data-v-b669f826=""
                                                                                  href="https://bingx.com/en/register/?redirect=https%3A%2F%2Fbingx.com%2Fen%2Fsupport%2Farticles%2F27362391466649%2F"
                                                                                  className="user-register"><span
                        data-v-b669f826="">
          Sign Up
        </span></a> <a data-v-b669f826=""
                       href="https://bingx.com/en/login/?redirect=https%3A%2F%2Fbingx.com%2Fen%2Fsupport%2Farticles%2F27362391466649%2F"
                       className="user-login"><span data-v-b669f826="">Log In</span></a></div>
                </div> : null}
        </header>
    );
};

export default Header;