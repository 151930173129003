import React from 'react';
import Wrapper from "./Components/Wrapper/Wrapper";
import Img from "./Assets/Img/img.jpg"
import './App.css'

const App = () => {
    return (
        <Wrapper>
            <div data-fetch-key="data-v-3f2962e2:0" className="article-container ignore-pxtorem" data-v-4117c5c5=""
                 data-v-3f2962e2="" data-v-71ee931c="">
                <div className="article-container-wrapper" data-v-4117c5c5="">
                    <div className="article-top-part ignore-pxtorem" data-v-51a492bf="" data-v-4117c5c5="">
                        <div className="header-wrapper ignore-pxtorem" data-v-08ae15d7="" data-v-51a492bf="">
                            <i className="bx-svg-warpper header-icon" style={{height: "28"}} data-v-331d72b6=""
                               data-v-08ae15d7="">
                                <i className="bx-svg-inner" style={{width: "28px", height: "28px "}}
                                   data-v-331d72b6=""/>
                            </i>
                            <div className="title" data-v-08ae15d7="">
                                Announcement Center
                            </div>
                            <i className="bx-svg-warpper search-icon" style={{height: "24", display: "none"}}
                               data-v-331d72b6="" data-v-08ae15d7=""><i className="bx-svg-inner"
                                                                        style={{width: "24px", height: "24px"}}
                                                                        data-v-331d72b6=""/></i>
                        </div>
                        <div className="search-ct" data-v-51a492bf="">
                            <div className="search-input-wrapper ignore-pxtorem" data-v-26fe742a="" data-v-51a492bf="">
                                <input type="text" placeholder="Search for an article" value="" className="search-input"
                                       data-v-26fe742a=""/>
                                <img
                                    src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTIwLjI5MjkgMjAuOTU3MUwyMSAyMS42NjQyTDIyLjQxNDIgMjAuMjVMMjEuNzA3MSAxOS41NDI5TDIwLjI5MjkgMjAuOTU3MVpNMTYuNTQyOSAxNy4yMDcxTDIwLjI5MjkgMjAuOTU3MUwyMS43MDcxIDE5LjU0MjlMMTcuOTU3MSAxNS43OTI5TDE2LjU0MjkgMTcuMjA3MVoiIGZpbGw9IiMxQTFBMUEiLz4KPGNpcmNsZSBjeD0iMTEuNjI1IiBjeT0iMTAuODc1IiByPSI4LjM3NSIgc3Ryb2tlPSIjMUExQTFBIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L3N2Zz4K"
                                    className="search-icon" data-v-26fe742a=""/>
                            </div>
                            <div className="cancel" style={{display: "none"}} data-v-51a492bf="">
                                Cancel
                            </div>
                        </div>
                    </div>
                    <div className="article-body-part ignore-pxtorem" data-v-5a12b539="" data-v-4117c5c5="">
                        <ul className="tab-list-wrapper ignore-pxtorem" data-v-4daa4338="" data-v-5a12b539="">
                            <li id="articleTabItem360004917494" className="tab-list__item" data-v-4daa4338=""><a
                                href="https://bingx.com/en/support/notice-center/360004917494" data-v-4daa4338=""><img
                                src="https://static-app.bb-os.com/image/ic-newest-announcement.svg" data="" alt=""
                                className="tab-list__item-icon" data-v-4daa4338=""/>
                                Latest Announcements
                            </a></li>
                            <li id="articleTabItem13719880054041" className="tab-list__item" data-v-4daa4338=""><a
                                href="https://bingx.com/en/support/notice-center/13719880054041" data-v-4daa4338=""><img
                                src="https://static-app.bb-os.com/image/ic_dongtai.svg" data="" alt=""
                                className="tab-list__item-icon" data-v-4daa4338=""/>
                                Latest Promotions
                            </a></li>
                            <li id="articleTabItem13698647206937" className="tab-list__item" data-v-4daa4338=""><a
                                href="https://bingx.com/en/support/notice-center/13698647206937" data-v-4daa4338=""><img
                                src="https://static-app.bb-os.com/image/ic_gengxin.svg" data="" alt=""
                                className="tab-list__item-icon" data-v-4daa4338=""/>
                                Product Updates
                            </a></li>
                            <li id="articleTabItem25908345932825" className="tab-list__item" data-v-4daa4338=""><a
                                href="https://bingx.com/en/support/notice-center/25908345932825" data-v-4daa4338=""><img
                                src="https://static-app.bb-os.com/image/ic_zichanbiangeng.svg" data="" alt=""
                                className="tab-list__item-icon" data-v-4daa4338=""/>
                                Funding
                            </a></li>
                            <li id="articleTabItem4483720972569" className="tab-list__item active" data-v-4daa4338=""><a
                                href="https://bingx.com/en/support/notice-center/4483720972569" data-v-4daa4338=""><img
                                src="https://static-app.bb-os.com/image/ic_xianhuoshagnxin.svg" data="" alt=""
                                className="tab-list__item-icon" data-v-4daa4338=""/>
                                Spot Listing
                            </a></li>
                            <li id="articleTabItem4486388996889" className="tab-list__item" data-v-4daa4338=""><a
                                href="https://bingx.com/en/support/notice-center/4486388996889" data-v-4daa4338=""><img
                                src="https://static-app.bb-os.com/image/ic-contract-listing.svg" data="" alt=""
                                className="tab-list__item-icon" data-v-4daa4338=""/>
                                Futures Listing
                            </a></li>
                            <li id="articleTabItem33435552402585" className="tab-list__item" data-v-4daa4338=""><a
                                href="https://bingx.com/en/support/notice-center/33435552402585" data-v-4daa4338=""><img
                                src="https://static-app.bb-os.com/image/asset_maintain.png" data="" alt=""
                                className="tab-list__item-icon" data-v-4daa4338=""/>
                                Asset Maintenance
                            </a></li>
                            <li id="articleTabItem4515307429273" className="tab-list__item" data-v-4daa4338=""><a
                                href="https://bingx.com/en/support/notice-center/4515307429273" data-v-4daa4338=""><img
                                src="https://static-app.bb-os.com/image/ic-delisting.svg" data="" alt=""
                                className="tab-list__item-icon" data-v-4daa4338=""/>
                                Delisting
                            </a></li>
                            <li id="articleTabItem26529427316121" className="tab-list__item" data-v-4daa4338=""><a
                                href="https://bingx.com/en/support/notice-center/26529427316121" data-v-4daa4338=""><img
                                src="https://static-app.bb-os.com/image/ic_qianbizhanyang.svg" data="" alt=""
                                className="tab-list__item-icon" data-v-4daa4338=""/>
                                Crypto Scout
                            </a></li>
                        </ul>
                        <div data-v-5a12b539="">
                            <div className="article-breadcrumbs ignore-pxtorem" data-v-429a4382="" data-v-5a12b539="">
                                <span className="breadcrumbs-item" data-v-429a4382="">
                                    <a href="https://bingx.com/en/" className="nuxt-link-active nav"  style={{color: "rgba(0, 0, 0, 0.5)"}} data-v-429a4382="">BingX</a>
                                    <i className="bx-svg-warpper split rtl-element-reverse" style={{height: "14",  margin: "0 0.16rem",
                                        color: "rgba(0, 0, 0, 0.5)"}}
                                       data-v-331d72b6="" data-v-429a4382="">
                                        <i className="bx-svg-inner" style={{
                                            width: "14px",
                                            height: "14px",
                                            maskImage: "url(https://static-app.bb-os.com/fe-common/svgAssets/ics-arrow.svg)"
                                        }} data-v-331d72b6=""/></i></span>
                                <span className="breadcrumbs-item" data-v-429a4382="">
                                    <a href="https://bingx.com/en/support/" className="nuxt-link-active nav"  style={{color: "rgba(0, 0, 0, 0.5)"}} data-v-429a4382="">Help Center</a>
                                    <i className="bx-svg-warpper split rtl-element-reverse" style={{height: "14",  margin: "0 0.16rem",
                                        color: "rgba(0, 0, 0, 0.5)"}}
                                       data-v-331d72b6="" data-v-429a4382="">
                                        <i className="bx-svg-inner" style={{
                                            width: "14px",
                                            height: "14px",
                                            maskImage: "url(https://static-app.bb-os.com/fe-common/svgAssets/ics-arrow.svg)"
                                        }} data-v-331d72b6=""/>
                                    </i>
                                </span>
                                <span className="breadcrumbs-item" data-v-429a4382="">
                                    <a href="https://bingx.com/en/support/notice-center/360004917494/" className="nav"  style={{color: "rgba(0, 0, 0, 0.5)"}} data-v-429a4382="">Announcement Center</a>
                                    <i className="bx-svg-warpper split rtl-element-reverse" style={{height: "14", margin: "0 0.16rem",
                                        color: "rgba(0, 0, 0, 0.5)"}}
                                       data-v-331d72b6="" data-v-429a4382="">
                                        <i className="bx-svg-inner" style={{width: "14px", height: "14px", maskImage:"url(https://static-app.bb-os.com/fe-common/svgAssets/ics-arrow.svg)"}}
                                           data-v-331d72b6=""/></i>
                                </span>
                                <span className="breadcrumbs-item" data-v-429a4382="">
                                    <a href="https://bingx.com/en/support/notice-center/4483720972569/"  style={{color: "rgba(0, 0, 0, 0.5)"}} className="nav"
                                       data-v-429a4382="">Spot Listing</a>
                                    <i className="bx-svg-warpper split rtl-element-reverse" style={{height: "14", margin: "0 0.16rem", color: "rgba(0, 0, 0, 0.5)"}}
                                       data-v-331d72b6="" data-v-429a4382="">
                                <i className="bx-svg-inner" style={{width: "14px", height: "14px", maskImage:"url(https://static-app.bb-os.com/fe-common/svgAssets/ics-arrow.svg)"}} data-v-331d72b6=""/></i>
                                </span>
                                <span className="breadcrumbs-item" data-v-429a4382="">
                                    <a href="https://bingx.com/en/support/articles/27362391466649/" aria-current="page"
                                       className="nuxt-link-exact-active nuxt-link-active nav current-nav"
                                       data-v-429a4382="">New Listing: MEGAETH/USDT</a>
                                </span>
                            </div>
                            <div className="article-detail-wrapper ignore-pxtorem" data-v-71c7bd0c="" data-v-3f2962e2=""
                                 data-v-5a12b539="">
                                <div className="article-title" data-v-71c7bd0c="">
                                    New Listing: MEGAETH/USDT
                                </div>
                                <div className="article-info" data-v-71c7bd0c=""><img
                                    src="https://bin.bb-os.com/_nuxt/img/bingx-logo.5dacfc7.png" alt=""
                                    className="author-avatar" data-v-71c7bd0c=""/>
                                    <div className="article-info__right" data-v-71c7bd0c="">
                                        <div className="author-name" data-v-71c7bd0c="">
                                            BingX
                                        </div>
                                        <div className="publish-time" data-v-71c7bd0c="">
                                            2024-01-08 18:05
                                        </div>
                                    </div>
                                </div>
                                <div className="article-body" data-v-71c7bd0c="">
                                    <div style={{whiteSpace: "pre", textAlign: "left"}}>
                                        <div data-page-id="CNkzd3e2Po54z7xi9gUuoneasLO"
                                             data-docx-has-block-data="false">
                                            <div
                                                className="ace-line ace-line old-record-id-doxusJcyKvOzEf00X9xFpSVmDUb">
                                                <span style={{color: " #1f2329"}}><img
                                                    src={Img}
                                                    alt="GGTK edited.jpg"/></span></div>
                                            <div
                                                className="ace-line ace-line old-record-id-doxusJcyKvOzEf00X9xFpSVmDUb">
                                                <span style={{color: " #1f2329"}}>Dear Users,</span></div>
                                            <div
                                                className="ace-line ace-line old-record-id-doxusCbC73j48JYwRphcgIOAOQy">&nbsp;</div>
                                            <div
                                                className="ace-line ace-line old-record-id-doxusLZ8o4CXiQvGIiBAPFuPzAe">
                                                <span style={{color: " #1f2329"}}>Due to popular demand. BingX Spot will add 1 trading pair on 2024-15-08, 10:00:00 (UTC+0).</span>
                                            </div>
                                            <div
                                                className="ace-line ace-line old-record-id-doxus7TKjvi5WK7bsJXKIfIHbtg">
                                                <span style={{color: " #1f2329"}}>• Asset</span>:<span
                                                style={{color: "#d83931"}}> </span><span
                                                style={{color: " #1f2329"}}>MEGAETH</span>
                                            </div>
                                            <div
                                                className="ace-line ace-line old-record-id-doxusiZ0lu6x10FGIuFxdoUmMSk">
                                                <span style={{color: " #1f2329"}}>• Trading Pair: MEGAETH/USDT</span></div>
                                            <div
                                                className="ace-line ace-line old-record-id-doxusyHuFGmpAttXsJ4s6lRCsZd">
                                                <div style={{whiteSpace: "pre", textAlign: "left"}}>
                                                    <div data-page-id="CNkzd3e2Po54z7xi9gUuoneasLO"
                                                         data-docx-has-block-data="false">
                                                        <div
                                                            className="ace-line ace-line old-record-id-doxusJcyKvOzEf00X9xFpSVmDUb">
                                                            <div data-page-id="CNkzd3e2Po54z7xi9gUuoneasLO"
                                                                 data-docx-has-block-data="false">
                                                                <div
                                                                    className="ace-line ace-line old-record-id-doxushuSJBvfUOc1VovwXFAcLxb">•
                                                                    MegaETH Deposit Time: 2024-15-08, 10:00:00 (UTC+0)
                                                                </div>
                                                                <div
                                                                    className="ace-line ace-line old-record-id-doxuszKHJPh2Sa6U5RlPEVnQ3qb">•
                                                                    MegaETH Withdrawal Time: 2024-16-08, 10:00:00 (UTC+0)
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className="ace-line ace-line old-record-id-doxusQS08ZNAPozJjBwkohG1cPf"></div>
                                            <div
                                                className="ace-line ace-line old-record-id-doxus9zuNuUXH7dcB5iuA7wfqKc">
                                                <div data-page-id="CNkzd3e2Po54z7xi9gUuoneasLO"
                                                     data-docx-has-block-data="false">
                                                    <h1 id="h_01HKS69C8TB7C8HTSXRFP8W1NQ"
                                                        className="ace-line ace-line old-record-id-doxus9zuNuUXH7dcB5iuA7wfqKc">About
                                                        MegaETH</h1>
                                                    <div
                                                        className="ace-line ace-line old-record-id-doxus9zuNuUXH7dcB5iuA7wfqKc">
                                                        <p>$MegaETH, or MegaETH Token, is the native cryptocurrency powering the
                                                            MegaETH DApp platform's Web3 gaming and DeFi ecosystem. Designed
                                                            to address the shortcomings of traditional gaming models,
                                                            $MegaETH operates on blockchain technology with smart
                                                            contracts, providing a transparent and efficient
                                                            infrastructure. Users can earn $MegaETH by playing games,
                                                            contributing to development, and providing liquidity. The
                                                            token's unique feature includes seamless integration with
                                                            meta transactions, eliminating the need for users to
                                                            navigate complex concepts like "gas" and "transaction
                                                            confirmation," thus enhancing the overall user experience
                                                            for both crypto and non-crypto users. Additionally, $MegaETH is
                                                            stakable, allowing users to earn rewards and a share of
                                                            platform commissions through staking activities.</p>
                                                        <p>The value of $MegaETH lies in its multifaceted role within the
                                                            MegaETH DApp ecosystem. Serving as a reward mechanism, $MegaETH
                                                            incentivizes player engagement, development contributions,
                                                            and liquidity provision. Staking $MegaETH offers holders
                                                            passive income opportunities and a share of platform
                                                            commissions from in-game purchases, promoting long-term
                                                            participation. The token also provides discounts for
                                                            platform fees and gaming NFT purchases, enhancing its value
                                                            proposition and encouraging widespread adoption within the
                                                            gaming community. With its seamless integration, $MegaETH
                                                            stands out as a versatile token that combines gaming and
                                                            decentralized finance, creating a unique and user-friendly
                                                            experience within the Web3 gaming space.</p>
                                                        <p>The MegaETH DApp ecosystem, driven by $MegaETH, follows a
                                                            success-sharing model, ensuring that all participants,
                                                            including players, developers, and liquidity providers, are
                                                            duly rewarded for their contributions. The platform's
                                                            roadmap outlines key milestones, including beta launches,
                                                            gaming project development, integration of external gaming
                                                            pools, and a mobile version, reflecting a commitment to
                                                            continuous improvement and expansion in the Web3 GameFi
                                                            universe. The strategic partnerships with Polygon and
                                                            recognition from industry leaders further reinforce MegaETH
                                                            DApp's position in the blockchain gaming landscape.</p>
                                                        <p>$MegaETH is set to be listed on BingX Exchange, providing users
                                                            with the opportunity to trade the token easily by selecting
                                                            the <a rel="nofollow"
                                                                   href="https://bingx.com/en-us/spot/GGTKUSDT/"
                                                                   target="_blank">MegaETH USDT trading pair</a>.
                                                            Comprehensive analyses, including price estimations, token
                                                            valuation, and insights on investment viability, will be
                                                            accessible through the "<a rel="nofollow"
                                                                                       href="https://bingx.com/en-us/prices/"
                                                                                       target="_blank">BingX Price
                                                                Analysis Tool</a>". To assist users in navigating the
                                                            process of purchasing cryptocurrencies, a detailed
                                                            step-by-step guide is available in the "<a rel="nofollow"
                                                                                                       href="https://bingx.com/en-us/allcrypto/"
                                                                                                       target="_blank">How
                                                                to Buy Crypto</a>" guide.</p>
                                                    </div>
                                                </div>
                                                <div data-page-id="CNkzd3e2Po54z7xi9gUuoneasLO"
                                                     data-docx-has-block-data="false"></div>
                                                <div data-page-id="CNkzd3e2Po54z7xi9gUuoneasLO"
                                                     data-docx-has-block-data="false"></div>
                                            </div>
                                            <br/>
                                            <div
                                                className="ace-line ace-line old-record-id-doxusWUdLoiZMQvsXhy6Nw447la">
                                                <strong>Investment Risk Warning: </strong></div>
                                            <div
                                                className="ace-line ace-line old-record-id-doxusWUdLoiZMQvsXhy6Nw447la">Cryptocurrencies
                                                are highly volatile and subject to various risks, including market,
                                                project, technical and compliance-related uncertainties. Therefore,
                                                you may experience potential investment losses due to high
                                                volatility and various risks associated. Exercise caution, stay
                                                informed on the potential risks and invest prudently.
                                            </div>
                                            <br/>
                                            <div
                                                className="ace-line ace-line old-record-id-doxusWUdLoiZMQvsXhy6Nw447lg">
                                                <strong>Note:</strong></div>
                                            <div
                                                className="ace-line ace-line old-record-id-doxus8YMBfdcmiBF832gyY9FtGf">1.
                                                Please make sure that the selected chain or network on BingX is
                                                consistent with the platform you're performing the withdrawal or
                                                deposit to avoid unnecessary losses.
                                            </div>
                                            <div
                                                className="ace-line ace-line old-record-id-doxusENoBNNqERKzGsAtPJCr1Jb">2.
                                                The platform will automatically adjust the relevant parameter
                                                limits for spot trading based on market liquidity. If you have
                                                any questions, please contact our 24/7 online support.
                                            </div>
                                            <div
                                                className="ace-line ace-line old-record-id-doxushcfTSfKxjD2J0ydW5LShTh">3.
                                                The listing time specified in the announcement is for reference
                                                only. We will open the trading service once the deposit
                                                requirement for the above asset is met. The actual opening time
                                                shall prevail.
                                            </div>
                                            <div
                                                className="ace-line ace-line old-record-id-doxus8tOb5vtY3b2yZpLyK13lUb">&nbsp;</div>
                                            <div
                                                className="ace-line ace-line old-record-id-doxuswtnNkGYTFL7FCdqCjVPUvc">
                                                <div style={{whiteSpace: "pre", textAlign: "left"}}>
                                                    <div data-page-id="CNkzd3e2Po54z7xi9gUuoneasLO"
                                                         data-docx-has-block-data="false">
                                                        <div
                                                            className="ace-line ace-line old-record-id-doxuse4VBgtq9nRTCQ5Azah1YMd">&nbsp;</div>
                                                        <div
                                                            className="ace-line ace-line old-record-id-doxus113IBgOfZSyMtZ3RG5hTE2">
                                                            <span style={{color: " #1f2329"}}><strong>BingX Operation Team</strong></span>
                                                        </div>
                                                        <div
                                                            className="ace-line ace-line old-record-id-doxusTfKtXU2howL4llNjq8mojh">
                                                                    <span
                                                                        style={{color: " #1f2329"}}><strong>2024-01-08</strong></span>
                                                        </div>
                                                        <div
                                                            className="ace-line ace-line old-record-id-doxusonSl1HJL8BBJB2Fzzg3ifg">&nbsp;</div>
                                                        <div
                                                            className="ace-line ace-line old-record-id-RCaydIigooCWY6xSoZSuY13ysWb">
                                                            <strong>BingX Official Channels</strong></div>
                                                        <div
                                                            className="ace-line ace-line old-record-id-KiYuduyg2oQMaux0IuNuGCRnsud">BingX
                                                            App: <a rel="nofollow"
                                                                    href="https://bingx.com/download/">https://bingx.com/download/</a>
                                                        </div>
                                                        <div
                                                            className="ace-line ace-line old-record-id-GyYQd6smSoEASexAzlsuiIn7suc">BingX
                                                            Web: <a rel="nofollow"
                                                                    href="https://bingx.com/">https://bingx.com</a>
                                                        </div>
                                                        <div
                                                            className="ace-line ace-line old-record-id-JykMdOWwyoImcAxqW19unSdOsBc">Telegram: <a
                                                            rel="nofollow"
                                                            href="https://t.me/BingXOfficial">https://t.me/BingXOfficial</a>
                                                        </div>
                                                        <div
                                                            className="ace-line ace-line old-record-id-VuqsdkEe4oumKyx886wumti2suh">Twitter: <a
                                                            rel="nofollow"
                                                            href="https://twitter.com/BingXOfficial">https://twitter.com/BingXOfficial</a>
                                                        </div>
                                                        <div
                                                            className="ace-line ace-line old-record-id-F4wOdi8eWo0ocyxC49kuWcAusre">YouTube: <a
                                                            rel="nofollow"
                                                            href="https://www.youtube.com/c/bingx">https://www.youtube.com/c/bingx</a>
                                                        </div>
                                                        <div
                                                            className="ace-line ace-line old-record-id-QeoKdkkGioSGIwxUFXPuw9Gbs2b">
                                                            <strong>Risk Disclaimer</strong></div>
                                                        <div
                                                            className="ace-line ace-line old-record-id-XQYIdMOeyokqIOxYlRDuRW3xsxh">Cryptocurrency
                                                            prices are subject to high market risk and price
                                                            volatility. You should only invest in products that
                                                            you are familiar with and where you understand the
                                                            associated risks. You should carefully consider your
                                                            investment experience, financial situation,
                                                            investment objectives and risk tolerance and consult
                                                            an independent financial adviser prior to making any
                                                            investment. This material is for reference only and
                                                            should not be construed as financial advice. Past
                                                            performance is not a reliable indicator of future
                                                            performance. The value of your investment can go
                                                            down as well as up, and you may not get back the
                                                            amount you invested. You are solely responsible for
                                                            your investment decisions. BingX is not responsible
                                                            for any losses you may incur.
                                                        </div>
                                                        <div
                                                            className="ace-line ace-line old-record-id-XYe8d2ss6oOucUxkvAtu0UabsGg">BingX
                                                            attaches great importance to compliance and strictly
                                                            abides by local regulations. Please obey local laws
                                                            and regulations in your country or region. BingX
                                                            reserves the right in its sole discretion to amend,
                                                            change, or cancel this announcement at any time and
                                                            for any reason without prior notice.
                                                        </div>
                                                        <div
                                                            className="ace-line ace-line old-record-id-XYe8d2ss6oOucUxkvAtu0UabsGg">For
                                                            more information, please refer to our <a
                                                                rel="nofollow"
                                                                href="https://bingx.com/support/categories/6840223998489/?sectionId=360005023793">Terms
                                                                of Use</a> and <a rel="nofollow"
                                                                                  href="https://bingx.com/support/categories/6840223998489/?sectionId=360008153893">Risk
                                                                Warning</a>.
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="register-footer" data-v-4117c5c5="">
                    <div className="register-box" data-v-4117c5c5=""><p className="text" data-v-4117c5c5="">
                        Sign up to claim rewards worth 5125 USDT
                    </p>
                        <div className="register-btn" data-v-4117c5c5=""><p className="label" data-v-4117c5c5="">
                            Sign Up
                        </p></div>
                    </div>
                </div>
            </div>
        </Wrapper>
    );
};

export default App;