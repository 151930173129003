import firstImage1 from '../Assets/Img/first/plus.svg';
import firstImage2 from '../Assets/Img/first/humans.svg';
import firstImage3 from '../Assets/Img/first/wallet.svg';

import secondImage1 from '../Assets/Img/second/1.svg';
import secondImage2 from '../Assets/Img/second/2.svg';
import secondImage3 from '../Assets/Img/second/3.svg';
import secondImage4 from '../Assets/Img/second/4.svg';
import secondImage5 from '../Assets/Img/second/5.svg';
import secondImage6 from '../Assets/Img/second/6.svg';

import thirdImage1 from '../Assets/Img/third/1.svg';
import thirdImage2 from '../Assets/Img/third/2.svg';
import thirdImage3 from '../Assets/Img/third/3.svg';

import fourImage1 from '../Assets/Img/four/1.svg';
import fourImage2 from '../Assets/Img/four/2.svg';
import fourImage3 from '../Assets/Img/four/3.svg';

import fiveImage1 from '../Assets/Img/five/1.svg';
import fiveImage2 from '../Assets/Img/five/2.svg';
import fiveImage3 from '../Assets/Img/five/3.svg';

import six1Image1 from '../Assets/Img/six/first/1.svg';
import six1Image2 from '../Assets/Img/six/first/2.svg';
import six1Image3 from '../Assets/Img/six/first/3.svg';
import six1Image4 from '../Assets/Img/six/first/4.svg';

import six2Image1 from '../Assets/Img/six/second/1.svg';
import six2Image2 from '../Assets/Img/six/second/2.svg';
import six2Image3 from '../Assets/Img/six/second/3.svg';
import six2Image4 from '../Assets/Img/six/second/4.svg';

import six3Image1 from '../Assets/Img/six/third/1.svg';
import six3Image2 from '../Assets/Img/six/third/2.svg';
import six3Image3 from '../Assets/Img/six/third/3.svg';
import six3Image4 from '../Assets/Img/six/third/4.svg';
import six3Image5 from '../Assets/Img/six/third/5.svg';
import six3Image6 from '../Assets/Img/six/third/6.svg';
import six3Image7 from '../Assets/Img/six/third/7.svg';


const images = {
    firstImage1,
    firstImage2,
    firstImage3,
    secondImage1,
    secondImage2,
    secondImage3,
    secondImage4,
    secondImage5,
    secondImage6,
    thirdImage1,
    thirdImage2,
    thirdImage3,
    fourImage1,
    fourImage2,
    fourImage3,
    fiveImage1,
    fiveImage2,
    fiveImage3,
    six1Image1,
    six1Image2,
    six1Image3,
    six1Image4,
    six2Image1,
    six2Image2,
    six2Image3,
    six2Image4,
    six3Image1,
    six3Image2,
    six3Image3,
    six3Image4,
    six3Image5,
    six3Image6,
    six3Image7,
};

export default images;